import Http from '../utils/HttpUtils';
import { API_SERVER_URL, API_SERVER_UPLOAD, HYUNDAI_SITE_ID } from '../constants';

// _______________________________________API Get content list____________________________________
const API_CONTENT_LIST = `${API_SERVER_URL}contents/listByTypeCode`;
const API_CAT_LIST = `${API_SERVER_URL}contents/list`;


// _______________________________________API report______________________________________________
const API_ADD_REPORT = `${API_SERVER_URL}report/insertReport`;
const API_REPLY_REPORT = `${API_SERVER_URL}report/replyReport`;
const API_SEARCH_REPORT = `${API_SERVER_URL}report/resultReport`;
const API_CONFIRM_REPORT = `${API_SERVER_URL}report/confirmReport`;
const API_POPUP_REPORT = `${API_SERVER_URL}contents/list`;
const API_GETLIST_SUB = `${API_SERVER_URL}/dataMaster/getValueListOfType`;
// _______________________________________API get menu____________________________________________
const API_SELECTALLMENU = `${API_SERVER_URL}mainpage/selectAllMenu`;

//________________________________________API post File___________________________________________
const API_UPLOADFILE = `${API_SERVER_UPLOAD}file/upload/report`;

const API_COMMON_DELETE_FILE = `${API_SERVER_UPLOAD}common/file/delete/report`;

export const API_DOWNLOAD_FILE_UPLOAD = `${API_SERVER_UPLOAD}file/downloadFileWithFileName`;

export const getContentList = (typeCode, lang) => {
  return Http.get(API_CONTENT_LIST, {
    params: {
      typeCode,
      lang: lang,
      sitesUrl: HYUNDAI_SITE_ID
    }
  });
};
export const getCategoryList = (categoryId, lang) => {
  return Http.get(API_CAT_LIST, {
    params: {
      categoryId,
      lang,
      urlSites: HYUNDAI_SITE_ID
    }
  });
};
export const selectAllMenu = (urlSites,lang) => {
  return Http.get(API_SELECTALLMENU, {
    params: {
      lang,
      urlSites
    }
  });
};

export const AddReport = (option, lang) => {
  return Http.post(API_ADD_REPORT, option, lang);
};

export const ReplyReport = (option) => {
  return Http.post(API_REPLY_REPORT, option);
};

export const SearchReport = params => {
  return Http.get(API_SEARCH_REPORT, {
    params: {...params,
    timestamp: new Date().getTime()}
  });
};

export const PopupReport = (lang) => {
  return Http.get(API_POPUP_REPORT, {
    params: {
      typeCode: 'POPUP',
      lang: lang,
      urlSites: HYUNDAI_SITE_ID
    }
  });
};

export const getListSubsidiary = (lang) => {
  return Http.get(API_GETLIST_SUB, {params: {lang: lang}});
};

export const ConfirmReport = option => {
  return Http.post(API_CONFIRM_REPORT, option);
};
const API_IMGURL = `${API_SERVER_URL}mainpage/selectImgUrl`;

export const getSelectImageUrl = params =>{
  return Http.get(API_IMGURL, {params});
};
export const uploadFile = (sitesUrl) => {
  return Http.post(API_UPLOADFILE,{
    sitesUrl
  });
};
export const commonDeleteFile = originalFileName => {
  return Http.post(API_COMMON_DELETE_FILE, originalFileName);
};

export const downloadFileUpload = params => {
  if(params.id){
    return `${API_DOWNLOAD_FILE_UPLOAD}?id=${params.id}&fileName=${params.fileName}`;
  } else {
    return `${API_DOWNLOAD_FILE_UPLOAD}?url=${params.url}&fileName=${params.fileName}&originalFileName=${params.originalFileName}`;
  }
};