import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import VisionComponent from '../../../components/DefaultContent/Philosophy/vision';
import './style.scss';

class Vision extends Component {
  componentDidMount() {
    var activeTabDom = document.getElementsByClassName('ethical_tab_childrent');
    if (this.props.tabClick != '3') {
      activeTabDom[2].focus();
      activeTabDom[2].click();
      setTimeout(() => {
        document.getElementsByClassName('et_link')[2].focus();
      }, 200); 
    }
    document.getElementsByClassName('et_link')[0].removeAttribute('title');
    document.getElementsByClassName('et_link')[1].removeAttribute('title');
    document.getElementsByClassName('et_link')[2].setAttribute('title', this.props.activeLanguage.code === 'en' ? 'Selected' : '선택됨');
    activeTabDom[2].className = 'ethical_tab_childrent ethical_active';
    activeTabDom[0].className = 'ethical_tab_childrent ethical_inactive';
    activeTabDom[1].className = 'ethical_tab_childrent ethical_inactive';
    this.props.func('0');
  }
  render() {
    const { data, activeLanguage } = this.props;
    const { code } = activeLanguage;
    if (data.length > 0) {
      return data.slice(0, 1).map((item, index) => {
        return (
          <div key={index} dangerouslySetInnerHTML={{ __html: item.content }}>
            {/* <div className="plcp_detail3">
              <span className="plcp_title" lang='en'>{item ? item.title : null}</span>
              {item.describe.split('\n').length > 1 ? (<div className="plcp_des_highlight">{item.describe.split('\n')[0]}</div>) : null}
              {item.describe.split('\n').length > 1 ? (<div className="plcp_des">{item.describe.substring(item.describe.split('\n')[0].length + 1, item.describe.length) }</div>) : <div className="plcp_des">{item.describe}</div>}
            </div>
            <div
              className="plcp_content3 real_plcp_content3"
              dangerouslySetInnerHTML={{ __html: item.content }}
            ></div> */}
          </div>
        );
      });
    } else {
      return <VisionComponent code={code} />;
    }
  }
}

export default withLocalize(Vision);
