import React, { useState } from "react";
import { withLocalize } from "react-localize-redux";
import * as yup from "yup";
import { Modal } from 'antd';
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { MESSAGE } from "../../../constants/error";
import { showToastError } from "../../../components/Modal";
import Loading from "../../../components/Loading";
import BannerContent from "../../../components/BannerContent";
import Input from "../../../components/Input";
import BreadCrumb from "../../../components/BreadCrumb";
import { SearchReport } from "../../../services/api";
import "./style.scss";

const Flex = styled.div`
  display: flex;
`;

const ConfirmProcess = props => {
  const { translate } = props;
  const schema = yup.object().shape({
    certificateNumber: yup
      .string()
      .required(MESSAGE.MSG7)
      .max(100, MESSAGE.MAXLENGTH_100)
  });

  // let [certificate, setCertificate] = useState('');
  let [errorText, setErrorText] = useState(false);
  let [loading, setLoading] = useState(false);
  let [notFive, setNotFive] = useState(false);
  let [visible, setVisible] = useState(false);


  const { register, handleSubmit, errors, formState } = useForm({
    validateCriteriaMode: "all",
    mode: "onChange",
    validationSchema: schema
  });
  const onSubmit = ({ certificateNumber }) => {
    setLoading(true);
    sessionStorage.setItem("code", certificateNumber.trim());
    // props.history.push('/report/report-detail', {
    //   certificateNumber
    // });
    let params = {};
    params.certificate = certificateNumber.trim();
    params.lang = props.activeLanguage.code;
    SearchReport(params)
      .then(response => {
        setErrorText(false);
        sessionStorage.setItem("code", certificateNumber.trim());
        sessionStorage.setItem(
          "reportDetail",
          JSON.stringify(response.data.data)
        );
        setLoading(false);
        props.history.push("/report/report-detail", {
          certificateNumber
        });
      })
      .catch(err => {
        if(err.response.data.code === 'HAE_006'){
          setVisible(true)
        }
        setLoading(false);
        setErrorText(true);
        // props.history.push('/not-found')
      });
  };
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.getElementById("backtotopconfirm").blur();
  };
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  // change validation messsage by language
  if (errors.certificateNumber) {
    if (errors.certificateNumber.types.required) {
      errors.certificateNumber.message = translate("report.certificateNumber");
    }
  }

  return (
    <div className="lr_background report_confirm_process">
      <Helmet>
        <title>{translate("helmet.reportconfirm")}</title>
      </Helmet>
      {loading ? <Loading /> : null}
      <BannerContent
        title={translate("reportProcess.bannerTitle")}
        content={translate("reportProcess.bannerContent1")}
        content_two={translate("reportProcess.bannarContent2")}
        background="/Images/Groupbanner31171.png"
      />
      <div className="report_confirmProcess">
        <BreadCrumb
          firstUrl={translate("reportProcess.firsturl")}
          secondUrl={translate("reportProcess.bannerTitle")}
          firstRouter="/report/guideline"
          secondRouter="/report/confirm-process"
        />
        <div className="rc_wrap">
          <div className="rcw_content">
            <div className="rcw_border">
              <div className="rcwc_list_item">
                <div className="rcwcl_item">
                  <div className="bgr_item">
                    <img
                      src="/Images/icreportorigin.svg"
                      alt=""
                      title={translate("webAccessibility.confirmStep1Title")}
                    />
                  </div>
                  <span>
                    <span>{translate("reportCertification.spanStep1")}</span>
                  </span>
                </div>
                <div className="rcwcl_item">
                  <div className="bgr_item">
                    <img
                      src="/Images/icreportnext11.svg"
                      alt=""
                      title={translate("webAccessibility.confirmStep2")}
                    />
                  </div>
                  <span>
                    <span>{translate("reportCertification.spanStep2")}</span>
                  </span>
                </div>
                <div className="rcwcl_item">
                  <div className="bgr_item">
                    <img
                      src="/Images/icreportsearch11.svg"
                      alt=""
                      title={translate("webAccessibility.confirmStep3")}
                    />
                  </div>
                  <span>
                    <span>{translate("reportCertification.spanStep3")}</span>
                  </span>
                </div>
                <div className="rcwcl_item">
                  <div className="bgr_item">
                    <img
                      src="/Images/icreportinactive.svg"
                      alt=""
                      title={translate("webAccessibility.confirmStep4")}
                    />
                  </div>
                  <span>
                    <span>{translate("reportCertification.spanStep4")}</span>
                  </span>
                </div>
              </div>
            </div>

            <div className="rcwc_detail">
              <div className="rcwcd_wrap">
                <div className="rcwcd_title">
                  {translate("reportProcess.content")}
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="rcwcd_input">
                    <div className="rcwce_image">
                      <img src="/Images/iclockblue.svg" alt="" />
                    </div>
                    <Input
                      forLabel={translate("reportProcess.labelInput")}
                      type="search"
                      classname_input="search_box"
                      inputTitle={translate("reportProcess.labelInput")}
                      hidden={false}
                      name="certificateNumber"
                      register={register({})}
                      errors={
                        errors.certificateNumber &&
                        errors.certificateNumber.message
                      }
                    />
                    <button
                      className="rcwce_btn_search"
                      onClick={handleSubmit(onSubmit)}
                      title={translate("TooltipReport.buttonConfirm")}
                    >
                      <span>{translate("reportProcess.btnCertification")}</span>
                    </button>
                  </div>
                </form>
                {formState.isValid && errorText ? (
                  <div className="rcwcd_error">
                    {translate("reportProcess.errEmpty")}
                  </div>
                ) : null}
                <div className="rcwcd_des">
                  <span>{translate("reportProcess.note")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Popup modal  */}
      <Modal
        className="modalAnony"
        visible={visible}
        footer={null}
        centered
        maskClosable
        width="522px"
        onCancel={e => {
          e.stopPropagation();
          setVisible(false);
        }}
        closeIcon={
          <img
            src="/Images/icclose36px.svg"
            alt={translate("webAccessibility.closeButton")}
          />
        }
      >
        <p className="content" style={{whiteSpace: 'pre-line', maxWidth: '100%'}}>
          {translate("reportProcess.errEmpty2")}
        </p>

      </Modal>

      <Flex className="btn_totop">
        <a
          href="javascript: void(0)"
          tabIndex="0"
          id="backtotopconfirm"
          onClick={scrollTop}
          onKeyPress={handleKeyPress}
        >
          <img
            src="/Images/icarrowupward48px.svg"
            alt={translate("TooltipHome.btnToTop")}
            title={translate("TooltipHome.btnToTop")}
          />
        </a>
      </Flex>
    </div>
  );
};
export default withLocalize(ConfirmProcess);
