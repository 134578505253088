

import React, { Component } from 'react';

import styled from 'styled-components';
import './style.scss';

const BackgroundBanner = styled.div`
  height: 240px;
  width: 100%;
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-position: 50% 44%;
  background-size: cover;
`;

const Flex = styled.div`
  display: flex;
`;
const HamonyB = styled.h1`
  font-family: HDharmonyB;
  // font-size: 40px;
  // line-height: 40px;
  // letter-spacing: -1.6px;
  // color: #ffffff;
`;
const HamonyL = styled.span`
  font-family: HDharmonyL;
  width: 35%;
  line-height:20px;
  letter-spacing: -0.64px;
`;
const HamonyN = styled.span`
  font-family: HDharmonyL;
  width: 50%;
  line-height:20px;
  letter-spacing: -0.64px;
`;
class BannerContent extends Component {
  render() {
    const { title, content, content_two, background} = this.props;
    return (
      <BackgroundBanner className="BannerContent" background={background}>
        <Flex className="bc_content">
          <HamonyB className="bcc_title">{title}</HamonyB>
          <HamonyL className="bcc_content">{content}</HamonyL>
          <HamonyN className="bcc_content_two">{content_two}</HamonyN>
        </Flex>
      </BackgroundBanner>
    );
  }
}
export default BannerContent;

