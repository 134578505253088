import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';

const SanHead = styled.div`
  font-family: HyundaiSansHeadKR;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
`;
const SanText = styled.div`
  font-family: notoMedium;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0;
  flex-direction: row;
  display: flex;
`;

export default ({
  inputTitle,
  hidden,
  type,
  text,
  Width,
  Margin,
  required,
  classname_input,
  disable = false,
  register,
  name,
  value,
  defaultValue,
  dateFormat = ['YYYY-MM-DD', 'YYYYMMDD'],
  onChange = () => { },
  errors,
  style,
  placeholder,
  errorDOB,
  getProp,
  // locale,
  dropdownClassName,
  inputReadOnly,
  selected,
  code,
  autoFocus,
  onSelect = () => { },
  onCalendarClose = () => { },
  ...props
}) => {
  return (
    <div
      id='date_picker_focus'
      style={{ ...style }}
      className={`${styles.inputText} input_Text ${classname_input}`}
    // onClick={() => {
    //   document.getElementById('date_picker_focus').focus();
    // }}
    >
      <SanText className={styles.inputTitle}>
        {inputTitle}
        {required && <p style={{ color: 'red', height: '0px' }}>*</p>}
        {/* <b hidden={hidden}>*</b> */}
      </SanText>

      <DatePicker
        autoComplete='off'
        showYearDropdown
        dropdownMode="select"
        dateFormat={code === 'kr' ? 'yyyy-MM-dd' : 'dd-MM-yyyy'}
        maxDate={new Date()}
        selected={selected ? new Date(selected) : null}
        inputReadOnly={inputReadOnly}
        dropdownClassName={dropdownClassName}
        disabled={disable}
        size="100px"
        className={styles.inputfield}
        // type={type}
        // value={!disable ? value : null}
        defaultValue={value}
        ref={register}
        onChange={e => {
          onChange(e);
        }}
        onSelect={() => {
          onSelect();
        }}
        onCalendarClose={()=>{
          onCalendarClose();
        }}
        autoFocus={autoFocus}
        name={name}
        {...props}
        format={dateFormat}
        placeholder={placeholder}
      />
      {errors ? <div className="error">{errors}</div> : null}
      {errorDOB ? <div className="error">{errorDOB}</div> : null}
      <SanHead className={styles.text} hidden={!disable}>
        {value && moment(value).format(dateFormat[0])}
      </SanHead>
    </div>
  );
};
