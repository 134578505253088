import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';
// import MaterialInput from '@material-ui/core/Input';
import styles from './styles.module.scss';
import styled from 'styled-components';
import moment from 'moment';

const SanHead = styled.div`
  font-family: HyundaiSansHeadKR;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
`;

const SanText = styled.div`
  font-family: notoMedium;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0;
  flex-direction: row;
  display: flex;
`;

export default ({
    inputTitle,
    className,
    value,
    placeholder,
    errors,
    errorDOB,
    mask,
    style,
    disabled,
    maskChar,
    titlePlaceholder,
    dateFormat,
    onChange = () => { },
    ...props
}) => {
    return (
        <div
            style={{ ...style }}
            className={styles.inputText}
        >
            <InputMask
                title={titlePlaceholder}
                id={inputTitle}
                mask={mask}
                maskChar={maskChar}
                value={value}
                placeholder={placeholder}
                onChange={(event) => {
                    onChange(event.target.value);
                }}
                className={`${styles.inputMask} ${className || ''}`}
                disabled={disabled}
                {...props}
            />

            {errors ? <div className="error">{errors}</div> : null}
            {errorDOB ? <div className="error">{errorDOB}</div> : null}
        </div>
    );
};
