import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePage from './pages/Home';
import EthicalManagement from './pages/EthicalManagement';
import ProgressEthical from './pages/EthicalManagement/ProgressEthical';
import Philosophy from './pages/Philosophy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import EthicsStandard from './pages/EthicsStandard';
import Report from './pages/Report';
import Faq from './pages/Faq';
import Example from './pages/Example';
import NotFound from './pages/NotFound';
import SiteMap from './pages/SiteMap';
import Layout from './hocs/Layout';

import './App.scss';
import './scss/font.scss';
import './scss/style.scss';

class App extends Component {
  componentDidMount(){
    document.addEventListener('focus', function(e){    
      if (e.target.classList && !e.target.classList.contains('child-menu') && !e.target.classList.contains('menu-item')) {
        var unfocusMenu = document.getElementsByClassName('menu-item');
        for (let index = 0; index < unfocusMenu.length; index++) {
          const element = unfocusMenu[index];
          element.children[1].removeAttribute('style');
          // element.children[1].style.visibility = 'none';
          element.children[1].style.height = '0';
          element.children[1].style.overflow = 'hidden';
        }
      }  
    }, true);
    setTimeout(() => {
      document.addEventListener('click',function(e){
        if(e.target.tagName=='A') {
          e.target.blur();
        }        
        else if(e.target.tagName=='SPAN' || e.target.tagName=='DIV')        
          e.target.parentElement.blur();
      });
    }, 200);
  }
  render() {
    return (
      <React.Fragment>
        {/* <Layout> */}
          <Switch>
            <Route path="/" component={HomePage} exact />        
            <Route path={'/example'} component={Example} />
            <Route path="/ethical-management" component={EthicalManagement} />
            <Route path="/progress" component={ProgressEthical} />
            <Route path="/philosophy" component={Philosophy} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/ethics-standard" component={EthicsStandard} />
            <Route path="/faq" component={Faq} />
            <Route path="/report" component={Report} />
            <Route path="/not-found" component={NotFound} />
            <Route path="/site-map" component={SiteMap} />
            <Route component={NoMatchPage} />
          </Switch>
        {/* </Layout> */}
      </React.Fragment>
    );
  }
}

const NoMatchPage = () => {
  if (window.location.pathname.indexOf('.do') >= 0) {
    return (
      <HomePage />
    );
  }
  return (
    <div id="main">
      <Layout>
        <NotFound />
      </Layout>
    </div>
  );
};


export default App;
