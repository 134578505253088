import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import LayoutEthical from '../../hocs/LayoutEthical';
import BreadCrumb from '../../components/BreadCrumb';
// import EthicalManagementElearning from "./Elearning";
// import WhatisElearning from './WhatIsEthical';
// import NecessityOfEthical from './NecessityOfEthical';
// import ProgressEthical from './ProgressEthical';
import { getContentList, getCategoryList } from '../../services/api';

import './style.scss';
class EthicalManagement extends Component {
  state = {
    activeTab: 0,
    data: [],
    dataContent: null,
    id: null
  };

  componentDidMount = () => {
    this.fetchData().then(response => {
      const { data } = response;
      if (data) {
        this.setState({
          data: data.data.listCategories[0].children
        });
        this.getdata(data.data.listCategories[0].children[0].id).then(
          response => {
            const { content } = response.data.data.contentsList[0];
            this.setState(
              {
                dataContent: content
              },
              () => {
              }
            );
            this.props.history.push(
              `/ethical-management/${data.data.listCategories[0].children[0].title}`
            );
          }
        );
      }
    });
  };

  fetchData = () => {
    return getContentList('ETHICALM', this.props.activeLanguage.code);
  };
  getdata = id => {
    return getCategoryList(id);
  };

  render() {
    const { path } = this.props.match;
    const { data } = this.state;
    const arrayPaths = path.split('/');
    if (arrayPaths.length > 2) {
    }
    return (
      <LayoutEthical>
        <BreadCrumb
          firstUrl={arrayPaths[1] ? '윤리경영' : null}
          secondUrl={arrayPaths[2] ? '윤리경영 이러닝' : null}
        />
        <div className="ethical_management_wrap">
          <div className="ethical_tab_wrap">
            <div className="ethical_tab">
              {data.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`ethical_tab_childrent ${
                      this.state.activeTab === index
                        ? 'ethical_active'
                        : 'ethical_inactive'
                    }`}
                    onClick={() => {
                      this.setState({
                        activeTab: index
                      });
                    }}
                  >
                    <Link
                      to={`${path}/${item.title}`}
                      onClick={() => {
                        this.getdata(item.id).then(response => {
                          const {
                            content
                          } = response.data.data.contentsList[0];
                          this.setState(
                            {
                              dataContent: content
                            },
                            () => {
                            }
                          );
                        });
                      }}
                    >
                      {item.title}
                    </Link>
                    <div className="arrowBtn"></div>

                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <ProgressEthical/> */}
        {/* <Switch>
          <Route path={`/progress`} component={ProgressEthical} />
          <Route path={`${path}`} component={ProgressEthical} />
        </Switch> */}
      </LayoutEthical>
    );
  }
}

export default withLocalize(EthicalManagement);
