import React, { useEffect } from "react";

export default code => {
  const clickContent = () => {
    let e = document.getElementsByClassName("tabIndex");
    for (let index = 0; index < e.length; index++) {
      const element = e[index];
      element.blur();
    }
  };

  if (code.code === "kr") {
    return (
      <div>
        <h1 className="hTagHidden">핵심가치</h1>
        <div className="plcp_detail2">
          <h1 className="plcp_title">
            도전과 열정으로 내재된 핵심가치를 실천할 때{" "}
            <br className="plcp_title_mobile" /> 현대자동차그룹의 심장은 더욱
            힘차게 뜁니다.
          </h1>
          {/* <div className="plcp_des_highlight">
            도전과 열정으로 내재된 핵심가치를 실천할 때 현대자동차그룹의 심장은
            더욱 힘차게 뜁니다.
          </div> */}
          <div className="plcp_des">
            현대자동차그룹의 경영철학은 전 임직원들의 가슴 깊이 새겨져 그룹의
            토대를 다지는 굳건한 정신입니다. 아무리 훌륭한 이론도 실천되지
            않는다면 가치를 발휘할 수 없습니다. 현대자동차그룹의 핵심가치는
            경영철학의 현장 실천을 구체적으로 대변하고 있습니다. 언제나 고객을
            최우선으로 생각하고 도전적 실행을 통해 가능성을 실현하며, 소통과
            협력의 조직 문화를 구축하고 개개인의 능력을 최대한 발현시키는 인재
            존중의 정신으로 글로벌 역량을 키워나갈 것입니다.
          </div>
        </div>
        <div className="plcp_content2 real_content_detail">
          <div className="content_detail ">
            <div className="cd_block_left">
              <div className="cdb_right">
                <span
                  lang="en"
                  tabIndex="0"
                  className="tabIndex"
                  onClick={clickContent}
                >
                  Customer
                </span>
              </div>
              <div className="cdb_line"></div>
              <div
                className="cdb_left tabIndex"
                tabIndex="0"
                onClick={clickContent}
              >
                <div className="cdbl_img">
                  <img
                    src="/Images/Group32710.svg"
                    alt=""
                    title="핵심가치. 고객최우선"
                  />
                </div>
                <h1 className="cdbl_title">고객최우선</h1>
                <div className="cdbl_desr">
                  최고의 품질과 최상의 서비스를 제공함으로써 모든 가치의 중심에
                  고객을 최우선으로 두는 고객감동의 기업 문화를 조성합니다.
                </div>
              </div>
            </div>
            <div className="cd_block_right">
              <div className="cdbr_layout">
                <div className="cdb_left">
                  <span
                    lang="en"
                    tabIndex="0"
                    className="tabIndex"
                    onClick={clickContent}
                  >
                    Challenge
                  </span>
                </div>
                <div className="cdb_line"></div>
                <div
                  className="cdb_right tabIndex"
                  tabIndex="0"
                  onClick={clickContent}
                >
                  <div className="cdbr_img">
                    <img
                      src="/Images/Group32692.svg"
                      alt="핵심가치. 도전적 실행"
                      title="핵심가치. 도전적 실행"
                    />
                  </div>
                  <h1 className="cdbr_title">도전적 실행</h1>
                  <div className="cdbr_desr">
                    현실에 안주하지 않고 새로운가능성에 도전하며 ‘할 수 있다’는
                    열정과 창의적 사고로 반드시 목표를 달성합니다.
                  </div>
                </div>
              </div>
            </div>
            <div className="cd_block_left">
              <div className="cdb_right cdb_border">
                <span
                  lang="en"
                  tabIndex="0"
                  className="tabIndex"
                  onClick={clickContent}
                >
                  Collaboration
                </span>
              </div>
              <div className="cdb_line"></div>
              <div
                className="cdb_left tabIndex"
                tabIndex="0"
                onClick={clickContent}
              >
                <div className="cdbl_img">
                  <img
                    src="/Images/Group32691.svg"
                    alt="핵심가치. 소통과 협력"
                    title="핵심가치. 소통과 협력"
                  />
                </div>
                <h1 className="cdbl_title">소통과 협력</h1>
                <div className="cdbl_desr">
                  타 부문 및 협력사에 대한 상호 소통과 협력을 통해 ‘우리’라는
                  공동체 의식을 나눔으로써 시너지효과를 창출합니다.
                </div>
              </div>
            </div>
            <div className="cd_block_right">
              <div className="cdbr_layout">
                <div className="cdb_left">
                  <span
                    lang="en"
                    tabIndex="0"
                    className="tabIndex"
                    onClick={clickContent}
                  >
                    People
                  </span>
                </div>
                <div className="cdb_line"></div>
                <div
                  className="cdb_right tabIndex"
                  tabIndex="0"
                  onClick={clickContent}
                >
                  <div className="cdbr_img">
                    <img
                      src="/Images/Group32690.svg"
                      alt="핵심가치. 인재존중"
                      title="핵심가치. 인재존중"
                    />
                  </div>
                  <h1 className="cdbr_title">인재존중</h1>
                  <div className="cdbr_desr">
                    우리 조직의 미래가 각 구성원들의 마음가짐과 역량에 달려
                    있음을 믿고 자기계발에 힘쓰며, 인재존중의 기업문화를 만들어
                    갑니다.
                  </div>
                </div>
              </div>
            </div>
            <div className="cd_block_left">
              <div className="cdb_right cdb_border">
                <span
                  lang="en"
                  tabIndex="0"
                  className="tabIndex"
                  onClick={clickContent}
                >
                  Globality
                </span>
              </div>
              <div className="cdb_line"></div>
              <div
                className="cdb_left tabIndex"
                tabIndex="0"
                onClick={clickContent}
              >
                <div className="cdbl_img">
                  <img
                    src="/Images/Group32693.svg"
                    alt="핵심가치. 글로벌 지향"
                    title="핵심가치. 글로벌 지향"
                  />
                </div>
                <h1 className="cdbl_title">글로벌 지향</h1>
                <div className="cdbl_desr">
                  문화와 관행의 다양성을 존중하며 모든 분야에서 글로벌 최고를
                  지향하고 글로벌 기업시민으로서 존경받는 개인과 조직이 되고자
                  합니다.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h1 className="hTagHidden">Core Value</h1>
        <div className="plcp_detail2">
          <h1 className="plcp_title">
            Living core values with passion and the spirit of challenge.
          </h1>
          {/* <div className="plcp_des_highlight">description</div> */}
          <div className="plcp_des">
            The management philosophy of Hyundai Motor Group is the foundation
            on which we strive to achieve our corporate vision. No matter how
            great an idea may be, it has no value if it is not upheld and
            practiced. Our core values are practical principles that guide us in
            fulfilling our management philosophy in day-to-day business. True to
            our management philosophy and core values, Hyundai Motor Group will
            grow global competency by putting customers first, embracing every
            opportunity for greater challenge, creating a orporate culture based
            on communication and collaboration, and empowering individuals to
            achieve their full potential.
          </div>
        </div>
        <div className="plcp_content2 real_content_detail">
          <div className="content_detail content_en">
            <div className="cd_block_left">
              <div className="cdb_right">
                <span className="tabIndex" tabIndex="0" onClick={clickContent}>
                  Customer
                </span>
              </div>
              <div className="cdb_line"></div>
              <div
                className="cdb_left tabIndex"
                tabIndex="0"
                onClick={clickContent}
              >
                <div className="cdbl_img">
                  <img
                    src="/Images/Group32710.svg"
                    alt="Core Value. CUSTOMER"
                    title="Core Value. CUSTOMER"
                  />
                </div>
                <h1 className="cdbl_title">CUSTOMER</h1>
                <div className="cdbl_desr">
                  We promote a customer-driven corporate culture by providing
                  the best quality and impeccable service with all values
                  centered on our customers.
                </div>
              </div>
            </div>
            <div className="cd_block_right">
              <div className="cdbr_layout">
                <div className="cdb_left">
                  <span
                    className="tabIndex"
                    tabIndex="0"
                    onClick={clickContent}
                  >
                    Challenge
                  </span>
                </div>
                <div className="cdb_line"></div>
                <div
                  className="cdb_right tabIndex"
                  tabIndex="0"
                  onClick={clickContent}
                >
                  <div className="cdbr_img">
                    <img
                      src="/Images/Group32692.svg"
                      alt="Core Value. CHALLENGE"
                      title="Core Value. CHALLENGE"
                    />
                  </div>
                  <h1 className="cdbr_title">CHALLENGE</h1>
                  <div className="cdbr_desr">
                    We refuse to be complacent, embrace every opportunity for
                    greater challenge, and are confident in achieving our goals
                    with unwavering passion and ingenious thinking.
                  </div>
                </div>
              </div>
            </div>
            <div className="cd_block_left">
              <div className="cdb_right cdb_border">
                <span className="tabIndex" tabIndex="0" onClick={clickContent}>
                  Collaboration
                </span>
              </div>
              <div className="cdb_line"></div>
              <div
                className="cdb_left tabIndex"
                tabIndex="0"
                onClick={clickContent}
              >
                <div className="cdbl_img">
                  <img
                    src="/Images/Group32691.svg"
                    alt="Core Value. COLLABORATION"
                    title="Core Value. COLLABORATION"
                  />
                </div>
                <h1 className="cdbl_title">COLLABORATION</h1>
                <div className="cdbl_desr">
                  We create synergy through a sense of &quot;togetherness&quot;
                  that is fostered by mutual communication and cooperation
                  within the company and with our business partners.
                </div>
              </div>
            </div>
            <div className="cd_block_right">
              <div className="cdbr_layout">
                <div className="cdb_left">
                  <span
                    className="tabIndex"
                    tabIndex="0"
                    onClick={clickContent}
                  >
                    People
                  </span>
                </div>
                <div className="cdb_line"></div>
                <div
                  className="cdb_right tabIndex"
                  tabIndex="0"
                  onClick={clickContent}
                >
                  <div className="cdbr_img">
                    <img
                      src="/Images/Group32690.svg"
                      alt="Core Value. PEOPLE"
                      title="Core Value. PEOPLE"
                    />
                  </div>
                  <h1 className="cdbr_title">PEOPLE</h1>
                  <div className="cdbr_desr">
                    We create synergy through a sense of
                    &quot;togetherness&quot; that is fostered by mutual
                    communication and cooperation within the company and with
                    our business partners.
                  </div>
                </div>
              </div>
            </div>
            <div className="cd_block_left">
              <div className="cdb_right cdb_border">
                <span className="tabIndex" tabIndex="0" onClick={clickContent}>
                  Globality
                </span>
              </div>
              <div className="cdb_line"></div>
              <div
                className="cdb_left tabIndex"
                tabIndex="0"
                onClick={clickContent}
              >
                <div className="cdbl_img">
                  <img
                    src="/Images/Group32693.svg"
                    alt="Core Value. GLOBALITY"
                    title="Core Value. GLOBALITY"
                  />
                </div>
                <h1 className="cdbl_title">GLOBALITY</h1>
                <div className="cdbl_desr">
                  We respect the diversity of cultures and customs, aspire to be
                  the world’s best at what we do, and strive to become a
                  respected global corporate citizen
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
