import React, { useEffect, useState } from 'react';
import { Slide } from 'react-slideshow-image';
import { withLocalize } from 'react-localize-redux';
// import ButtonText from '../Buttons';
import { getSelectImageUrl } from '../../services/api';
import { API_SERVER_UPLOAD, HYUNDAI_SITE_ID } from '../../constants';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './style.scss';
const Banner = ({ translate, ...props }) => {
  const {
    activeLanguage: { code }
  } = props;
  let [selectedItem, setSelectedItem] = useState(0); //set state to change tabindex
  const [slideImages1, setSlideImage1] = useState([]);
  const [stopSlide, setStopSlide] = useState(true);
  // const [isLoading,setLoading] =useState(true);

  useEffect(() => {
    getSelectImageUrl({ sitesUrl: HYUNDAI_SITE_ID })
      .then(res => {
        const slides = res.data.data.map(item => {
          const url = `${API_SERVER_UPLOAD}file/showImage?id=${item.id}`;
          return encodeURI(url);
        });
        setSlideImage1(slides);
        //  setLoading(false);
      })
      .catch(err => {});
    let buttonSliddingNext = document.getElementsByClassName('control-next');
    for (let index = 0; index < buttonSliddingNext.length; index++) {
      const element = buttonSliddingNext[index];
      element.setAttribute('alt', translate('banner.next'));
    }
    let buttonSliddingPrev = document.getElementsByClassName('control-prev');
    for (let index = 0; index < buttonSliddingPrev.length; index++) {
      const element = buttonSliddingPrev[index];
      element.setAttribute('alt', translate('banner.prev'));
    }
    let dotClass = document.getElementsByClassName('dot');
    for (let index = 0; index < dotClass.length; index++) {
      const element = dotClass[index];
      element.setAttribute(
        'alt',
        translate('webAccessibility.dot') + (index + 1)
      );
    }
    changeTabIndex();

    let slideWrap = document.getElementsByClassName('carousel-root');
    slideWrap[0].setAttribute('tabindex', '-1');

  }, [selectedItem]);
  const changeSlide = () => {
    let date = new Date();
    let milisecond = date.getMilliseconds();
    setSelectedItem(milisecond); // to run after rendering
  };
  //function change tabIndex
  const changeTabIndex = () => {
    let activeSlide = document.getElementsByClassName('slider');
    loop1: for (let index = 0; index < activeSlide.length; index++) {
      const element = activeSlide[index];
      const childrennotSelect = element.getElementsByClassName('slide');
      for (let index3 = 0; index3 < childrennotSelect.length; index3++) {
        const element = childrennotSelect[index3];
        element
          .getElementsByClassName('banner_link')[0]
          .setAttribute('tabindex', '-1');
      }
      let children = element.getElementsByClassName('selected');
      loop2: for (let index2 = 0; index2 < children.length; index2++) {
        const element = children[index2];
        if (
          children.length > 1 &&
          (children[index2] == childrennotSelect[0] ||
            children[index2] == childrennotSelect[childrennotSelect.length - 1])
        ) {
          element.classList.remove('selected');
          index2--;
          continue loop2;
        }
        element
          .getElementsByClassName('banner_link')[0]
          .setAttribute('tabindex', '0');
      }
    }
  };
  // const properties = {
  //   duration: 5000,
  //   transitionDuration: 500,
  //   infinite: true,
  //   indicators: true,
  //   arrows: true,
  //   autoplay: stopSlide,
  //   pauseOnHover: true,
  // };
  return (
    <div className="slide-container">
      {slideImages1.length > 0 ? (
        <Carousel
          showArrows={true}
          autoPlay={stopSlide}
          infiniteLoop={true}
          stopOnHover={true}
          showStatus={false}
          showThumbs={false}
          // selectedItem={selectedItem}
          onChange={changeSlide.bind(this)}
        >
          <div className="each-slide">
            <div
              className="text_wrap"
              style={{ backgroundImage: `url(${slideImages1[0]})` }}
            >
              <div className="es-content">
                <div className="es-subtitle">
                  {translate('slideShow.titleSubtitle1')}
                </div>
                <div className="es-title">Together for a better future</div>
                <div className="es-subtitle-2">
                  {translate('slideShow.contentSubtitle1')}
                </div>
              </div>
              {/* <ButtonText text="자세히 보기" TextLink="/philosophy" /> */}
              <a
                tabIndex="-1"
                className="banner_link"
                href="/philosophy"
                alt={translate('webAccessibility.banner1')}
                title={translate('webAccessibility.banner1')}
                onFocus={() => {
                  setStopSlide(false);
                }}
                onBlur={() => {
                  setStopSlide(true);
                }}
              >
                <span>{translate('btn.goBanner')}</span>
                <img src="" alt="" />
              </a>
            </div>
          </div>
          <div className="each-slide">
            <div
              className="text_wrap"
              style={{ backgroundImage: `url(${slideImages1[1]})` }}
            >
              <div className="es-content">
                {/* <div className="es-subtitle">상생</div> */}
                <div className="es-title">
                  {translate('slideShow.titleSubtitle2')}
                </div>
                <div className="es-subtitle-2">
                  {translate('slideShow.contentSubtitle2Line1')}
                </div>
                <div className="es-subtitle-2 es-subtitle-2_mobile">
                  {translate('slideShow.contentSubtitle2Line2')}
                </div>
              </div>
              <a
                tabIndex="-1"
                className="banner_link"
                target="_blank"
                rel="noopener noreferrer"
                alt={translate('webAccessibility.banner2')}
                title={translate('webAccessibility.banner2')}
                href={
                  code === 'kr'
                    ? 'https://www.hyundai.co.kr/About-US/Coexistence1.hub'
                    : 'https://www.hyundaimotorgroup.com/About-Us/Coexistence1.hub'
                }
                onFocus={() => {
                  setStopSlide(false);
                }}
                onBlur={() => {
                  setStopSlide(true);
                }}
              >
                <span>{translate('btn.goBanner')}</span> <img src="" alt="" />
              </a>
            </div>
          </div>
          <div className="each-slide">
            <div
              className="text_wrap"
              style={{ backgroundImage: `url(${slideImages1[2]})` }}
            >
              <div className="es-content">
                <div className="es-subtitle">
                  {translate('slideShow.titleSubtitle3')}
                </div>
                <div className="es-title">
                  Trustworthy Partner <br />
                  for Today & Tomorrow
                </div>
                <div className="es-subtitle-2">
                  {translate('slideShow.contentSubtitle3')}
                </div>
              </div>
              <a
                tabIndex="-1"
                className="banner_link"
                target="_blank"
                rel="noopener noreferrer"
                alt={translate('webAccessibility.banner3')}
                title={translate('webAccessibility.banner3')}
                href={
                  code === 'kr'
                    ? 'https://www.hyundai.co.kr/CSR/Overview/CSR-System.hub'
                    : 'https://www.hyundaimotorgroup.com/CSR/Overview/CSR-System.hub'
                }
                onFocus={() => {
                  setStopSlide(false);
                }}
                onBlur={() => {
                  setStopSlide(true);
                }}
              >
                <span>{translate('btn.goBanner')}</span> <img src="" alt="" />
              </a>
            </div>
          </div>
        </Carousel>
      ) : (
        <Carousel
          showArrows={true}
          autoPlay={stopSlide}
          infiniteLoop={true}
          stopOnHover={true}
          showStatus={false}
          showThumbs={false}
        >
          <div className="each-slide">
            <div
              className="text_wrap"
              style={{ backgroundImage: 'url(/Images/banner1.jfif)' }}
            >
              <div className="es-content">
                <div className="es-subtitle">
                  {translate('slideShow.titleSubtitle1')}
                </div>
                <div className="es-title">Together for a better future</div>
                <div className="es-subtitle-2">
                  {translate('slideShow.contentSubtitle1')}
                </div>
              </div>
              {/* <ButtonText text="자세히 보기" TextLink="/philosophy" /> */}
              <a
                className="banner_link"
                href="/philosophy"
                alt={translate('TooltipHome.btnSlider1')}
                title={translate('TooltipHome.btnSlider1')}
                onFocus={() => {
                  setStopSlide(false);
                }}
                onBlur={() => {
                  setStopSlide(true);
                }}
              >
                <span>{translate('btn.goBanner')}</span>
                <img src="" alt="" />
              </a>
            </div>
          </div>
          <div className="each-slide">
            <div
              className="text_wrap"
              style={{ backgroundImage: 'url(/Images/banner2.jfif)' }}
            >
              <div className="es-content">
                {/* <div className="es-subtitle">상생</div> */}
                <div className="es-title">
                  {translate('slideShow.titleSubtitle2')}
                </div>
                <div className="es-subtitle-2">
                  {translate('slideShow.contentSubtitle2Line1')}
                </div>
                <div className="es-subtitle-2 es-subtitle-2_mobile">
                  {translate('slideShow.contentSubtitle2Line2')}
                </div>
              </div>
              <a
                className="banner_link"
                target="_blank"
                rel="noopener noreferrer"
                alt={translate('TooltipHome.btnSlider2')}
                title={translate('TooltipHome.btnSlider2')}
                href={
                  code === 'kr'
                    ? 'https://www.hyundai.co.kr/About-US/Coexistence1.hub'
                    : 'https://www.hyundaimotorgroup.com/About-Us/Coexistence1.hub'
                }
                onFocus={() => {
                  setStopSlide(false);
                }}
                onBlur={() => {
                  setStopSlide(true);
                }}
              >
                <span>{translate('btn.goBanner')}</span> <img src="" alt="" />
              </a>
            </div>
          </div>
          <div className="each-slide">
            <div
              className="text_wrap"
              style={{ backgroundImage: 'url(/Images/banner3.jfif)' }}
            >
              <div className="es-content">
                <div className="es-subtitle">
                  {translate('slideShow.titleSubtitle3')}
                </div>
                <div className="es-title">
                  Trustworthy Partner <br />
                  for Today & Tomorrow
                </div>
                <div className="es-subtitle-2">
                  {translate('slideShow.contentSubtitle3')}
                </div>
              </div>
              <a
                className="banner_link"
                target="_blank"
                rel="noopener noreferrer"
                alt={translate('TooltipHome.btnSlider3')}
                title={translate('TooltipHome.btnSlider3')}
                href={
                  code === 'kr'
                    ? 'https://www.hyundai.co.kr/CSR/Overview/CSR-System.hub'
                    : 'https://www.hyundaimotorgroup.com/CSR/Overview/CSR-System.hub'
                }
                onFocus={() => {
                  setStopSlide(false);
                }}
                onBlur={() => {
                  setStopSlide(true);
                }}
              >
                <span>{translate('btn.goBanner')}</span> <img src="" alt="" />
              </a>
            </div>
          </div>
        </Carousel>
      )}
    </div>
  );
};

export default withLocalize(Banner);
