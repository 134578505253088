import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import LanguageWrap from './Languages/LanguageRealName';
import LogoHyundaiAudit from './Logo/LogoRealName';
import Menu from './Menu';
import SideBar from './SideBar';

import './style.scss';

const Wrap = styled.div``;

const HeaderRealName = (props) => {
  const {type} = props;

  let [width, setWidth]= useState(window.innerWidth);
  const pathName = window.location.pathname.slice(1);
  const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;
  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setWidth(getWidth());
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);
 
    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  return (
    <Wrap className="header_Wrap header-wrap">
      <SideBar setOverFlow={props.setOverFlow}/>
      {pathName && width < 1199 ? (<span className='hw_title'>{type}</span>) : <LogoHyundaiAudit />}
      <Menu />
      {pathName && width < 1199 ? <div style={{width: '20%'}}></div>: (<LanguageWrap />)}
    </Wrap>
  );
};

export default HeaderRealName;