import Http from "../../utils/HttpUtils";
import { API_SERVER_URL, HYUNDAI_SITE_ID, API_SERVER_UPLOAD } from "../../constants";

//Progress
const API_CONTENT_PROGRESS = `${API_SERVER_URL}contents/contentInProgressList`;
const API_CONTENT_FAQ = `${API_SERVER_URL}contents/contentOfFAQ`;

// const API_SELECTALLMENU = `${API_SERVER_URL}audit/mainpage/selectAllMenmu`;

export const apiListContentProgress = (lang) => {
  return Http.get(API_CONTENT_PROGRESS, {
    params: {
      urlSites: HYUNDAI_SITE_ID,
      lang: lang
    }
  });
};

export const apiListContentFaq = param => {
  return Http.get(
    `${API_CONTENT_FAQ}?typeCode=${param.typeCode}&page=${param.page}&pageSize=${param.pageSize}`
  );
};

//Privacy
const API_CONTENT_LIST_PRIVACY = `${API_SERVER_URL}contents/list`;
const API_CONTENT_LIST_PUBLIC_DATE = `${API_SERVER_URL}contents/publicDate`;
const API_CONTENT_PRIVACY_ID = `${API_SERVER_URL}contents/detail`;
const API_DOWNLOAD_FILE_UPLOAD_CONTENT = `${API_SERVER_UPLOAD}file/downloadContent`;

export const apiListContentPrivacy = (lang) => {
  return Http.get(API_CONTENT_LIST_PRIVACY, {
    params: {
      typeCode: "PRIVACY",
      lang: lang,
      urlSites: HYUNDAI_SITE_ID
    }
  });
};

export const apiListPublicDate = (lang, contentId) => {
  return Http.get(API_CONTENT_LIST_PUBLIC_DATE, {
    params: {
      lang,
      typeCode: "PRIVACY",
      contentId: contentId,
      urlSites: HYUNDAI_SITE_ID
    }
  });
};

export const apiContentPrivacyById = (publicDate, lang) => {
  return Http.get(API_CONTENT_PRIVACY_ID, {
    params: {
      typeCode: 'PRIVACY',
      publicDate: publicDate,
      lang: lang
    }
  });
};

//Ethics Standard
export const apiListPublicDateEthics = (lang, id, contentId) => {
  return Http.get(API_CONTENT_LIST_PUBLIC_DATE, {
    params: {
      lang: lang,
      typeCode: "ETHICALS",
      urlSites: HYUNDAI_SITE_ID,
      contentId: contentId,
      categoryId: id
    }
  });
};

export const apiContentEthicsById = (id, publicDate, lang) => {
  return Http.get(API_CONTENT_PRIVACY_ID, {
    params: {
      categoryId: id,
      publicDate: publicDate,
      lang: lang
    }
  });
};
export const downloadFileUploadDettailContentAPI = params => {
  return Http.get(API_DOWNLOAD_FILE_UPLOAD_CONTENT, {params: {id: params.id, fileName: params.fileName}});
};
export const downloadFileUploadDetailContent = params => {
  return `${API_DOWNLOAD_FILE_UPLOAD_CONTENT}?id=${params.id}&fileName=${params.fileName}`;
};