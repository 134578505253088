import { put, all, takeEvery, call } from 'redux-saga/effects';
import { apiListContentProgress } from '../../services/api/index';
import * as Type from '../types/ethicalManagement';

async function asyncAPI(api, data) {
  return await api(data);
}

export function* apiGetListContentProgress(action) {
  try {
    let url = action.urlSites;
    let lang = action.lang;
    const res = yield call(asyncAPI, apiListContentProgress, url, lang);    
    if (res.data) {
      const content = res.data.data ? res.data.data.contentProgressList : [];
      yield put({ type: Type.GET_ETHICAL_CONTENT_PROGRESS_SUCCESS, payload: {listContentProgress: content } });
    }
  } catch (e) {
    yield put({ type: Type.GET_ETHICAL_CONTENT_PROGRESS_FAILURE, payload: e.message });
  }
}

/**
 * Make a root saga
 *
 * @returns {object} - Generator functions all combined in one saga
 */

export default function* rootSaga() {
  yield all([
    takeEvery(Type.GET_ETHICAL_CONTENT_PROGRESS, apiGetListContentProgress),
  ]);

}
