import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { Helmet } from 'react-helmet';
import './style.scss';

const Example = props => {
  const {activeLanguage, translate} = props;
  const {code} = activeLanguage;

  return(
    <div className="modalStart">
        <Helmet htmlAttributes={{ lang : code === 'kr' ? 'ko' : code }}>
          <title>{translate('helmet.example')}</title>
        </Helmet>
      <div>
        <div className="modalStart_title">
          {code === 'kr' ? '제보 작성 사례':'Example of Report (举报示例)'}
        </div>
        {code === 'kr' ? (
          <div className="modalStart_content">
            <div className="msc_item">
              <div className="msci_left msci_item">
                <span className="title">이름 :</span>
                <span className="content">나현대(익명 선택 가능)</span>
              </div>
              <div className="msci_right msci_item">
                <span className="title">생년월일 :</span>
                <span className="content">1967-12-29</span>
              </div>
            </div>
            <div className="msc_item">
              <div className="msci_item msci_mobile_email">
                <span className="title" lang='en'>E-Mail :</span>
                <div className="content">
                  OOO@hyundai.com(연락가능 메일)
                </div>
              </div>
            </div>
            <div className="msc_item">
              <div className="msci_left msci_item">
                <span className="title">전화번호 :</span>
                <span className="content">02-1234-1234</span>
              </div>
              <div className="msci_right msci_item">
                <span className="title">핸드폰번호 :</span>
                <span className="content">010-1234-1234</span>
              </div>
            </div>
            <div className="msc_item">
              <div className="msci_item msci_mobile_2">
                <div className="title">제목 :</div>
                <div className="content">
                  현대자동차 OO 팀 나기아 부장의 OO 업체로 부터 금품 수수
                  제보
                </div>
              </div>
            </div>
            <div className="msc_item bb_0">
              <div className="msci_item msci_content">
                <div className="title">
                  작성요령(최대한 자세히 기록 바랍니다.)
                </div>
                <div className="item">
                  <div>
                    1. 제보하고자 하는 내용 <br /> : 내가 했거나 당한 일임, 내가 직접 보거나 들음, 직장동료/업체 등에게 들음, 소문으로 알게 된 내용임 등
                  </div>
                </div>
                <div className="item">
                  <div>
                    2. 제공자(업체) <br />: OO 업체 대표 홍길동, 영업과장 나일등
                  </div>
                </div>
                <div className="item">
                  <div>
                    3. 제보 대상 <br />: OOO 사업부 OOO팀 나기아 차장, 너현대 과장
                  </div>
                </div>
                <div className="item">
                  <div>
                    4. 부정비리 상세내용(시기, 장소, 규모)
                    <br /> : &apos;17년 12월 경 나기아 차장에게 OO업체 OOO 대표가 현금 OO만원을 
                    <br/>흰 봉투에 넣어 OO횟집에서...(나기아 차장 아내의 통장으로...)
                    <br /> &apos;17년 1월 1일 너현대 과장에게 OO업체 OO과장이 역삼동 OO고급주점에서 접대...
                  </div>
                </div>
                <div className="item">
                  <div>
                    5. 접대/향응 제공 목적 
                    <br />: OO 프로젝트 수주 특혜 목적/입찰정보 제공 요청 등
                  </div>
                </div>
                <div className="item">
                  <div>
                  6. 기타 내용
                  <br/>: 해당 내용을 알 것으로 예상되는 사람은 OOO이고, 연락처는 010-1234-1234입니다.
                  <br/>사실 확인을 위한 방법은 OOO입니다. 등의 추가 내용 기입
                  </div>
                </div>
              </div>
            </div>
            <div className="msc_item msc_item_last">
              <span>※ </span>
              <div>
                제보내용(실명,특정업체명 등)이 구체적이지 않을 경우 조사가
                지연되거나 보류될 수 있으니 최대한 자세히 기록바라며, <span>근거없는 비방 및 험담 등은 종결
                  처리 될 수 있음을 양지 바랍니다.
                </span>
              </div>
              <div></div>
            </div>
          </div>
        ) : (
          <div className="modalStart_content">
            <div className="msc_item">
              <div className="msci_left msci_item">
                <span className="title">Name :</span>
                <span className="content"> John Smith </span>
              </div>
              <div className="msci_right msci_item">
                <span className="title">Date of birth (出生日期) :</span>
                <span className="content">29-Dec-1967</span>
              </div>
            </div>
            <div className="msc_item">
              <div className="msci_item msci_mobile_email">
                <div className="title">E-Mail (E-mail) :</div>
                <div className="content"> □□□＠gmail.com </div>
              </div>
            </div>
            <div className="msc_item">
              <div className="msci_left msci_item">
                <span className="title">Phone number (电话号码) :</span>
                <span className="content">82(Nation code)212341234</span>
              </div>
              <div className="msci_right msci_item">
                <span className="title">Mobile Phone (手机号码) :</span>
                <span className="content">821012341234</span>
              </div>
            </div>
            <div className="msc_item">
              <div className="msci_item msci_mobile_2">
                <div className="title title_en">Report title (标题) :</div>
                <div className="content">
                  Report on bribery from △ company to Hyundai Motor □ Team
                </div>
              </div>
            </div>
            <div className="msc_item bb_0">
              <div className="msci_item msci_content">
                <div className="title">
                  Write instructions (please record as much detail as
                  possible)<br/>
                  填写说明（请尽量进行详细描述）
                </div>
                <div className="item">
                  <span className="left">1.</span>
                  <div>
                    What I would like to report:
                    <br />
                    - What I&#39;ve done or done, I&#39;ve seen or heard in
                    person, i hear it from a co-worker/business partner,
                    <br />- What I&#39;ve learned from rumors, etc.
                  </div>
                </div>
                <div className="item">
                  <span className="left">1.</span>
                  <div>
                    举报内容：
                    <br />
                    - 本人所做的事或遭遇的事、本人亲眼看见或亲耳听到的事、从同事或合作方处得知的事
                    <br />- 本人听说的传言等
                  </div>
                </div>
                <div className="item">
                  <span className="left">2.</span>
                  <div>
                    Suppliers: OO Company Ceo Hong Gil-dong, Sales Manager
                    Nile, etc.
                  </div>
                </div>
                <div className="item">
                  <span className="left">2.</span>
                  <div>
                    提供人（企业）：XX公司CEO 张三，营业科长 李四等
                  </div>
                </div>
                <div className="item">
                  <span className="left">3.</span>
                  <div>Report: OOO Deputy Director, OOO Team</div>
                </div>
                <div className="item">
                  <span className="left">3.</span>
                  <div>举报对象： XXX事业部 XXXTeam XXX部长，XX公司负责人 张三</div>
                </div>
                <div className="item">
                  <span className="left">4.</span>
                  <div>
                    Details of the irregularities (timing, place, scale):
                    <br />
                    - &#39;Around December 17, ooo representative of OO
                    company OOO put the cash OO won in a white bag... (As a
                    passbook of Nagia Deputy Director Wife...)
                    <br />- &lsquo;January 17, 17, OOOO Company (Executive director ooo) provides entertain to Kimㅇㅇ(Hyundai) at ㅇㅇ karaoke (Kangnam station).&hellip;
                  </div>
                </div>
                <div className="item">
                  <span className="left">4.</span>
                  <div>
                    违规详细内容（时间、地点、规模）： 
                    <br />
                    - 2017年12月左右，XX公司 XXX负责人在XXX餐厅将现金XXX万元装进白色信封给了张三次长……（用张三妻子的存折……）
                    <br />- 2017年1月17日XX公司XXX专务在江南站的某高级酒店招待了现代汽车的XXX科长……
                  </div>
                </div>
                <div className="item">
                  <span className="left">5.</span>
                  <div>
                    The purpose of entertainment/entertainment is: oo
                    project order preferential purpose / request to provide
                    bid information, etc.
                  </div>
                </div>
                <div className="item">
                  <span className="left">5.</span>
                  <div>
                    招待目的：XX项目采购特惠、要求提供招标资料等
                  </div>
                </div>
                <div className="item">
                  <span className="left">6.</span>
                  <div>
                    Other information: Ooo is the person who is expected to
                    know the information, and contact sit at 010-1234-1234.
                    Ooo is the way to verify the facts. Fill out additional
                    details such as&hellip;.
                  </div>
                </div>
                <div className="item">
                  <span className="left">6.</span>
                  <div>
                    其他内容：XXX对该事项也有所了解，可通过010-1234-1234与之联系。可通过XX方法对事实进行验证。其他详细信息如下……
                  </div>
                </div>
              </div>
            </div>
            <div className="msc_item msc_item_last">
              <span>※ </span>
              <div>
                If the report (real name, specific company name, etc.) is
                not specific, the investigation may be delayed or suspended,
                so please note that the unfounded slander and gossip may be
                closed.
              </div>
              <div>&nbsp;</div>
            </div>
            <div className="msc_item msc_item_last">
              <span>※ </span>
              <div>
                如举报信息（真实姓名、具体公司名称等）不明确，可能会导致调查延迟或暂停，请尽可能地进行详细叙述。毫无依据的诽谤、诋毁等内容将会被关闭。
              </div>
              <div>&nbsp;</div>
            </div>
          </div>
        )}
      </div>
  </div>
  );
};

export default withLocalize(Example);