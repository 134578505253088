import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { withLocalize } from "react-localize-redux";
import SideBarItem from "./Item";
import { selectAllMenu } from "../../../services/api";
import { HYUNDAI_SITE_ID } from "../../../constants";

const SanHead = styled.span`
  font-family: HyundaiSansHeadKR;
`;
const Text = styled.div`
  a {
    font-family: HyundaiSansHeadKR;
    text-decoration: none;
  }
`;

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickSideBar = this.handleClickSideBar.bind(this);
  }
  state = {
    dataMenu: [],
    classHidden: "hidden",
    sideBar: "hidden"
  };

  onSetSidebarClose() {
    this.setState({
      classHidden: "hidden"
    });
    this.props.setOverFlow("");
  }
  onSetSidebarOpen() {
    this.setState({
      classHidden: "active"
    });
    this.props.setOverFlow("layout_overflow");
  }
  componentDidMount() {
    this.loadMenu(this.props);
    document.addEventListener("mousedown", this.handleClickSideBar);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.activeLanguage.code !== nextProps.activeLanguage.code) {
      this.loadMenu(nextProps);
    }
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickSideBar);
  }

  // /**
  //  * Set the wrapper ref
  //  */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * change state if clicked on outside of element
   */
  handleClickSideBar(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        classHidden: "hidden"
      });
      this.props.setOverFlow("");
    } else {
      this.setState({
        classHidden: "active"
      });
      this.props.setOverFlow("layout_overflow");
    }
  }

  loadMenu = async props => {
    let lang = props.activeLanguage.code;
    let urlSites = HYUNDAI_SITE_ID;
    let result = await selectAllMenu(urlSites, lang);
    this.setState({
      dataMenu: result.data.data.menuInfoTree
    });
  };

  render() {
    const { translate } = this.props;
    const data = this.state.dataMenu;
    return (
      <div className="click_show">
        <img
          src="/Images/MaskGroup203.svg"
          alt={translate("webAccessibility.menuBarMobile")}
          onClick={() => this.onSetSidebarOpen()}
        />
        <div
          className={`sideBar ${this.state.classHidden}`}
          ref={this.setWrapperRef}
        >
          <div className="side_bar_wrap_content">
            <img
              style={{
                width: "16px",
                position: "absolute",
                left: "16px",
                top: "57px"
              }}
              src="/Images/icclose36px.svg"
              alt={translate("webAccessibility.closeMenuMobile")}
              onClick={() => this.onSetSidebarClose()}
            />
            <div className="wrap_content">
              <a
                href="/"
                style={{
                  padding: "0 16px",
                  fontFamily: "notoMedium",
                  color: "#000",
                  fontSize: "17px",
                  lineHeight: "48px",
                  letterSpacing: "-0.34px"
                }}
              >
                HOME
              </a>
              {data
                ? data.slice(0, 3).map((item, index) => {
                    return (
                      <SideBarItem
                        key={index}
                        text={item.name}
                        line1="기업이념체계"
                        line2="추진경과"
                        data={item.children}
                      />
                    );
                  })
                : null}
              <div className="side_bar_item_wrap">
                <div className="wrap_5">
                  <div className="content">
                    {/* <Text className="row_5-text">
                      <img src="/Images/icdns48px.svg" alt="" />
                      <Link to="/">
                        <SanHead>E-book</SanHead>
                      </Link>
                    </Text>
                    <Text className="row_5-text">
                      <img src="/Images/icdns48px.svg" alt="" />
                      <Link to="/">
                        <SanHead>사이트 맵</SanHead>
                      </Link>
                    </Text> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <img
            src="/Images/Group35905.svg"
            alt=""
            style={{
              position: 'relative'
            }}
          /> */}
        </div>
      </div>
    );
  }
}

export default withLocalize(SideBar);
