import React from 'react';
import './style.scss';

const Loading = () => {
  return (
    <div className="loading_component">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default Loading;
