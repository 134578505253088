import React from 'react';

import './style.scss';

export default ({
  textTitle,
  forLabel,
  ClassNameTextarea,
  name,
  value,
  disabled=false,
  register,
  errors,
  placeholder,
  onFocus = () => {},
  onBlur = () => {},
  onChange = () => { },
  hidden = true,
}) => {
  return (
    <div className={`textarea ${ClassNameTextarea}`}> 
      <label htmlFor={forLabel}>
        {textTitle}
        <b hidden={hidden}>*</b>
      </label>
      <textarea
        id={forLabel}
        disabled={disabled}
        placeholder={placeholder}
        rows={6}
        ref={register}
        name={name}
        value={value}
        onFocus={e => {
          onFocus(e);
        }}
        onBlur={e => {
          onBlur(e);
        }}
        onChange={e => {
          onChange(e);
        }}
      ></textarea>
      {errors ? <div className="error">{errors}</div> : null}
    </div>
  );
};
