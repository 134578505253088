import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { withLocalize } from 'react-localize-redux';
import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import styled from 'styled-components';
import Loading from '../../../components/Loading';
import BannerContent from '../../../components/BannerContent';
import BreadCrumb from '../../../components/BreadCrumb';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import Upload from '../../../components/Input/Upload';
import { GBSize, MBSize } from '../../../constants';
import { MESSAGE } from '../../../constants/error';
import { ReplyReport, commonDeleteFile, } from '../../../services/api';
import './style.scss';

const Flex = styled.div`
  display: flex;
`;
const schema = yup.object().shape({
  titleReport: yup
    .string()
    .required(MESSAGE.MSG7)
    .typeError(MESSAGE.MSG10)
    .max(256, MESSAGE.MAXLENGTH_256),
  content: yup
    .string()
    .required(MESSAGE.MSG7)
    .typeError(MESSAGE.MSG10)
    .max(5000, MESSAGE.MAXLENGTH_5000),
  listFiles: yup.array()
});

const Reply = ({ history, activeLanguage, translate }) => {
  const { location } = history;
  // const { code } = activeLanguage;
  const { pathname } = location;
  let [loading, setLoading] = useState(false);
  let [listFiles, setListFiles] = useState([]);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [isValidFile, setIsValidFile] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const {
    register,
    handleSubmit,
    errors,
    // setError,
    control,
    // reset,
    setValue,
    formState,
    // getValues
  } = useForm({
    validateCriteriaMode: 'all',
    mode: 'onChange',
    validationSchema: schema
  });
  const report_detail = JSON.parse(sessionStorage.getItem('reportDetail'));
  let {
    statusCode,
  } = report_detail;
  // if(statusCode !== '5'){
  //   history.push('/');
  // }
  const onSubmit = ({ titleReport, content, listFiles, certificate }) => {
    // const {
    //   location: { state = {} }
    // } = history;
    // const { certificateNumber } = state;
    setLoading(true);
    ReplyReport({
      title: titleReport,
      content: content,
      certificate: sessionStorage.getItem('code'),
      listFiles: []
    })
      .then(response => {
        setLoading(false);
        const { data } = response;
        sessionStorage.setItem('code', data.data);
        history.push('/report/certificate');
      })
      .catch(err => {});
  };
  const onUploadDone = e => {
    if (e.size > 0) {
      setTotalFileSize(totalFileSize + e.size);
      if ((totalFileSize + e.size) / GBSize > 1) {
        setIsValidFile(false);
        setErrorMsg(translate('createReport.errorUpload'));
      }
    }
  };
  const onRemove = e => {
    if (e.size > 0) {
      if (e.size / MBSize > 50) {
        setIsValidFile(true);
        setErrorMsg('');
      } else {
        setTotalFileSize(totalFileSize - e.size);
        if ((totalFileSize - e.size) / GBSize < 1) {
          setIsValidFile(true);
          setErrorMsg('');
        }
      }
    }
    const data = {
      originalFileName: e.originFileObj ? e.originFileObj.name : e.name
    };
    commonDeleteFile(data)
      .then(res => {})
      .catch(e => {});
  };
  const onErrorFile = res => {
    setIsValidFile(false);
    setErrorMsg(translate('createReport.errorMessage'));
  };
   // change validation messsage by language
   if (errors.titleReport) {
    if (errors.titleReport.types.required) {
      errors.titleReport.message = translate('reportReply.titleRequired');
    } else if (errors.titleReport.types.max) {
      errors.titleReport.message = translate('reportReply.titleMax');
    }
  }
  if (errors.content) {
    if (errors.content.types.required) {
      errors.content.message = translate('reportReply.contentRequired');
    }
  }
  
  return (
    <div className="report_reply">
      {loading ? <Loading /> : null}
      <Helmet>
        <title>HYUNDAI MOTOR GROUP Management Ethics</title>
      </Helmet>
      <BannerContent
        background="/Images/Groupbanner31171.png"
        title={translate('reportReply.bannerTitle')}
        content={translate('reportDetail.bannerContent')}
      />
      <div className="rr_content_wrap">
        <div className="wrap">
          <BreadCrumb
            firstRouter="/report/guideline"
            secondRouter="/report/report-reply"
            firstUrl={translate('createReport.banner')}
            secondUrl={translate('reportReply.bannerTitle')}
          />
          <div className="rrcw_content">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="content_report">
                <div className="rrcwc_form">
                  <div className="rf_title">{translate('reportReply.contentTitle')}</div>
                  <Input
                    forLabel={translate('reportReply.contentLable1')}
                    classname_input="rf_input"
                    inputTitle={translate('reportReply.contentLable1')}
                    hidden={false}
                    name="titleReport"
                    errors={errors.titleReport && errors.titleReport.message}
                    register={register({})}
                  />
                  <div className="rf_des">
                    <TextArea
                      textTitle={translate('reportReply.contentLable2')}
                      hidden={false}
                      name="content"
                      errors={errors.content && errors.content.message}
                      register={register({})}
                    />
                  </div>
                  <Controller
                    className="rf_upload"
                    as={<Upload />}
                    sitesUrl={pathname}
                    onError={(e, res) => onErrorFile(res)}
                    onUploadDone={e => onUploadDone(e)}
                    onRemove={e => onRemove(e)}
                    control={control}
                    hidden={false}
                    classname_input="rf_upload"
                    name="listFiles"
                    setValue={setValue}
                  />
                  {/* <div className="rf_notice">
                    {translate('reportReply.contentNote')}
                  </div> */}
                </div>
              </div>
              <div className="confirm_btn">
                <button
                  onClick={handleSubmit(onSubmit)}
                  // disabled={!formState.isValid}
                >
                  {translate('reportReply.btnSubmit')}
                </button>
                <Link to="/report/report-detail">{translate('reportReply.btnCancel')}</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Flex className="btn_totop">
        <img
          src="/Images/icarrowupward48px.svg"
          alt={translate('TooltipHome.btnToTop')}
          onClick={scrollTop}
          title={translate('TooltipHome.btnToTop')}
        />
      </Flex>
    </div>
  );
};

export default withLocalize(Reply); 