export const MESSAGE = {
  MSG1:
    '선택한 파일 크기가 너무 큽니다. 50MB 미만의 파일을 선택하십시오',
  MSG2: '선택한 파일의 형식이 잘못되었습니다.',
  MSG3: '파일을 선택하지 않아 작업에 실패했습니다.',
  MSG4: '',
  MSG5: '양의 정수를 입력하십시오.',
  MSG6: '{숫자} 자리 미만을 입력하십시오.',
  MSG7: '필수 항목입니다.',
  MSG8: '형식 날짜가 올바르지 않습니다. YYYY-MM-DD 형식으로 날짜를 입력하십시오',
  MSG9: '검색과 일치하는 결과가 없습니다.',
  MSG10: '값 텍스트 만 입력하십시오.',
  MSG11:
    '페이지 제목을 비활성화 할 수 없습니다. 필요한 경우 정보를 업데이트하십시오',
  MSG12: '코드를 복제 할 수 없습니다. 다른 값을 입력하십시오',
  MSG13:
    '이 역할은 현재 사용자에게 할당되어 있습니다. 비활성화 하시겠습니까?',
  MSG14:
    '선택한 이미지 크기가 너무 큽니다. 2MB 미만의 파일을 선택하십시오.',
  MSG15:
    '여기에 * .JPG, * .PNG, * GIF, * .JPEG 파일을 첨부 할 수 있습니다.',
  MSG16:
    '최소 크기는 1440 x 628입니다.',
  MSG26_1: '생년월일이 올바르지 않습니다.',
  MSG40_1: '전화번호 형식이 올바르지 않습니다.',
  MSG_SPACE: '공간뿐만 아니라 입력하십시오.',
  MAXLENGTH_3: '제목은 3자 이하로 입력해주세요.',
  MAXLENGTH_5: '제목은 5자 이하로 입력해주세요.',
  MAXLENGTH_5_EN: 'Please enter less than 5 characters.',
  MAXLENGTH_50: '제목은 50자 이하로 입력해주세요.',
  MAXLENGTH_100: '제목은 100자 이하로 입력해주세요.',
  MAXLENGTH_150: '제목은 150자 이하로 입력해주세요.',
  MAXLENGTH_20: '제목은 20자 이하로 입력해주세요.',
  MAXLENGTH_20_EN: 'Please enter less than 20 characters.',
  MAXLENGTH_30: '제목은 30자 이하로 입력해주세요.',
  MAXLENGTH_30_EN: 'Please enter less than 30 characters.',
  MAXLENGTH_255: '제목은 255자 이하로 입력해주세요.',
  MAXLENGTH_256: '제목은 256자 이하로 입력해주세요.',
  MAXLENGTH_300: '제목은 300자 이하로 입력해주세요.',
  MAXLENGTH_500: '제목은 500자 이하로 입력해주세요.',
  MAXLENGTH_1000: '제목은 1000자 이하로 입력해주세요.',
  MAXLENGTH_5000: '제목은 5000자 이하로 입력해주세요.',
  MAXLENGTH_16000: '16,000자를 넘는 경우, 파일로 업로드 부탁드립니다.',
  //Role

  C_MSG_1: '새로운 아이템이 성공적으로 생성되었습니다.',
  C_MSG_2: '이 조치는 되돌릴 수 없습니다. 선택한 항목을 삭제 하시겠습니까?',
  C_MSG_3: '선택한 항목이 성공적으로 삭제되었습니다.',
  C_MSG_4: '역할 코드는 복제 할 수 없습니다. 다른 값을 입력하십시오.',
  C_MSG_5: '작업이 실패했습니다! 다시 시도하십시오. 문제가 계속되면 관리자에게 문의하십시오.',
  C_MSG_6: '항목이 성공적으로 업데이트되었습니다.',
  C_MSG_7: '종료일은 시작일 이전 일 수 없습니다',
  C_MSG_8: '유효하지 않은 날짜입니다. 정확한 날짜를 입력하십시오.',
  C_MSG_9: '이 과정을 취소 하시겠습니까?',
  C_MSG_10: '데이터가 없습니다',
  C_MSG_11: '이메일 또는 전화가 이미 존재합니다',
  MSG_16: '코드를 복제 할 수 없습니다. 다른 값을 입력하십시오',

  // status code
  HAE_001: 'HAE_001', //Duplicate
  R_200: 'R_200',     //Success
};

// export const MESSAGE = {
//     MSG1:
//       'The selected file size to too large. Please select a file less than 50MB',
//     MSG2: 'The selected file is in the wrong format.',
//     MSG3: 'Operation failed because no file was selected.',
//     MSG4: '',
//     MSG5: 'Please enter positive integer number.',
//     MSG6: 'Please enter less than {number} digits.',
//     MSG7: '필수 항목입니다.',
//     MSG8: 'Format date is incorrect. Please input date with format \'YYYY-MM-DD\'',
//     MSG9: 'There are no results match your search.',
//     MSG10: 'Please input value text only.',
//     MSG11:
//       'Tittle of the page is unable to be Inactive. Please update the information if needed',
//     MSG12: 'Code cannot be duplicated. Please input other value',
//     MSG13:
//       'This role is currently assigned for user, are you sure to inactivate it?',
//     MSG14:
//       'The selected image size to too large. Please select a file less than 2MB.',
//     MSG15:
//       'You can just attach *.JPG, *.PNG, *GIF, *.JPEG files here',
//     MSG16:
//       'The minimum dimension would be 1440 x 628',
//     MSG_SPACE: 'Input not only space .',
//     MAXLENGTH_3: 'Please enter less than 3 characters.',
//     MAXLENGTH_5: 'Please enter less than 5 characters.',
//     MAXLENGTH_50: 'Please enter less than 50 characters.',
//     MAXLENGTH_100: 'Please enter less than 100 characters.',
//     MAXLENGTH_150: 'Please enter less than 150 characters.',
//     MAXLENGTH_30: 'Please enter less than 30 characters.',
//     MAXLENGTH_255: 'Please enter less than 255 characters.',
//     MAXLENGTH_256: 'Please enter less than 256 characters.',
//     MAXLENGTH_300: 'Please enter less than 300 characters.',
//     MAXLENGTH_500: 'Please enter less than 500 characters.',
//     MAXLENGTH_1000: 'Please enter less than 1000 characters.',
//     MAXLENGTH_5000: 'Please enter less than 5000 characters.',
//     //Role

//     C_MSG_1: 'New item is created successfully.',
//     C_MSG_2: 'This action cannot be reversed. Are you sure want to delete selected item?',
//     C_MSG_3: 'Selected item is deleted successfully.',
//     C_MSG_4: 'Role Code cannot be duplicated. Please input other value.',
//     C_MSG_5: 'The operation failed! Please try again. If the problem continues, contact administrator.',
//     C_MSG_6: 'Item is updated successfully.',
//     C_MSG_7: 'End Date cannot be before Start date',
//     C_MSG_8: 'Invalid date, please input correct date.',
//     C_MSG_9: 'Are you sure that you want to cancel this process?',
//     C_MSG_10: 'There is no data',
//     C_MSG_11: 'Email or phone already exists',
//     MSG_16: 'Code cannot be duplicated. Please input other value',

//     // status code
//     HAE_001: 'HAE_001', //Duplicate
//     R_200: 'R_200',     //Success
//   };
