import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import { withLocalize } from 'react-localize-redux';
import './style.scss';

class BreadCrumb extends Component {
  render() {
    const { firstUrl, secondUrl, firstRouter, secondRouter, translate } = this.props;
    if (secondUrl) {
      return (
        <div className="breadcrumb_component">
           <a href='/' className='bc_home'>
            <img src="/Images/ichome48px.svg" alt={translate('webAccessibility.breadcrumbHome')} title={translate('webAccessibility.breadcrumbHome')} tabIndex="-1" />
          </a>
          <a href={firstRouter} className='bc_first'>
            {firstUrl}
          </a>
          <a href={secondRouter} className='bc_second'>
            {secondUrl}
          </a>
        </div>
      );
    }
    else {
      return (
        <div className="breadcrumb_component">
          <a href='/' className='bc_home'>
            <img src="/Images/ichome48px.svg" alt={translate('webAccessibility.breadcrumbHome')} title={translate('webAccessibility.breadcrumbHome')} tabIndex="-1" />
          </a>
          <a href={firstRouter} className='bc_first'>
            {firstUrl}
          </a>
        </div>
      );
    }

  }
}
//   renderItem = this.props.breadcrumb.map((el, index) => {
//     return <Breadcrumb.Item key={index}>{el.lv1}</Breadcrumb.Item>;
//   });

//   render() {
//     return (
//       <div className="">
//         <Breadcrumb>
//           <Breadcrumb.Item>
//             <Link to='/home'>
//               <img src='/Images/ic_home.svg' alt="" />
//             </Link>
//           </Breadcrumb.Item>
//           {this.renderItem}
//         </Breadcrumb>
//       </div>
//     );
//   }
// }
export default withLocalize(BreadCrumb);
