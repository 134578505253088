import React from 'react';
import { withLocalize } from 'react-localize-redux';
import './style.scss';

const NotFound = ({translate, ...props}) => {
  return (
    <div className="not_found">
      <div className="nf_wrap">
        <div className="nfw_title">{translate('notfound.title')}</div>
        <div className="nfw_content">{translate('notfound.content')}</div>
        <a href="/">{translate('notfound.button')}</a>
      </div>
    </div>
  );
};

export default withLocalize(NotFound);