import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import styles from "./style.module.scss";
class ButtonText extends Component {
  render() {
    const { text, TextLink } = this.props;
    const Wrap = styled.div``;
    return (
      <Wrap className={`${styles.WrapButton} home-button`}>
        <button className={`${styles.wb_button} hb-wrap-button`}>
          <Link className={styles.wbb_link} to={TextLink}>
            <span>{text}</span>
            <div className="Wbb_inactive">  
              <img src='/Images/ickeyboardarrowright48px.svg' alt="" />
            </div>
            {/* <div className="Wbb_active">  
              <img src='/Images/icarrowrightblue.svg' alt="" />
            </div> */}
          </Link>
        </button>
      </Wrap>
    );
  }
}

export default ButtonText;
