import React from 'react';
// import { Link } from 'react-router-dom';

import styled from 'styled-components';
import './style.scss';
import { withLocalize } from 'react-localize-redux';

const Wrap = styled.div``;
// const Text = styled.div`
//   a {
//     font-family: HyundaiSansHeadKR;
//     text-decoration: none;
//   }
// `;

  // constructor(props) {
  //   super(props);
  //   this.state ={
  //     hidden: "hidden"
  //   }
  //   this.setWrapperRef = this.setWrapperRef.bind(this);
  //   this.handleClickOutside = this.handleClickOutside.bind(this);
  // }

  // componentDidMount() {
  //   document.addEventListener('mousedown', this.handleClickOutside);
  // }

  // componentWillUnmount() {
  //   document.removeEventListener('mousedown', this.handleClickOutside);
  // }

  // /**
  //  * Set the wrapper ref
  //  */
  // setWrapperRef(node) {
  //   this.wrapperRef = node;
  // }

  // /**
  //  * change state if clicked on outside of element
  //  */
  // handleClickOutside(event) {
  //   if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
  //     this.setState({
  //       hidden: "hidden"
  //     })
  //   }
  //   else {
  //     this.setState({
  //       hidden: "active"
  //     })
  //   }
  // }
const languageWrap =({ languages, activeLanguage, translate, setActiveLanguage, ...props }) =>{
    return (
      <Wrap className="languageWrap">
        {/* <Text className="l_Text">
          <img src="/Images/Component 49 – 31.svg" alt="" />
          <Link to="/ebook" className="lt_Obj_hidden">Ebook</Link>
        </Text> */}
        <a title={translate('webAccessibility.titlebuttonReport')} className='btn_report' href='/report/guideline'>{translate('btn.button-report')}</a>
      </Wrap>
    );
  };

export default withLocalize(languageWrap);
