import { fork} from 'redux-saga/effects';
import ethicalSaga from './ethicalSaga';
import faqSaga from './faqSage';

/**
 * Make a root saga
 *
 * @returns {object} - Generator functions all combined in one saga
 */

export default function* rootSaga() {
  yield fork(ethicalSaga);
  yield fork(faqSaga);
}
