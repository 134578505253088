import React from 'react';
import styled from 'styled-components';
import styles from  './style.module.scss';
import './style.scss';

const SanHead = styled.div`
  font-family: HDharmonyL;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.64px;
`;
const SanText = styled.label`
  font-family: HyundaiSansTextKR;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0;
`;
const blackListKeyPhone = [46,69,107,109,187,189,231,190, 110,16];
const whiteListKeyPhone = [8,48,49,50,51,52,53,54,55,56,57,96,97,98,99,100,101,102,103,104,105];
const regex = /[0-9]/;
export default ({
  inputTitle,
  titlePlaceholder,
  forLabel,
  hidden,
  type,
  text,
  Width,
  maxLength,
  Margin,
  classname_input,
  disable = false,
  register,
  defaultValue,
  name,
  autoFocus,
  // min,
  // max,
  value,
  typeDetail,
  onChange = () => {},
  onKeyDown = e => {
    var e = window.event || e;
    var key = e.keyCode;
    // let result = false;
    // let resultB = true;
    // if (!whiteListKeyPhone.includes(key)) {
    //   e.preventDefault();
    // }
    // blackListKeyPhone.map(element => {
    //   if(element == key) {
    //     resultB = false;
    //   }
    // })
    // if(resultB) {
    //   whiteListKeyPhone.map(element => {
    //     if(element == key) {
    //       result = true;
    //     }
    //   })
    //   if(!result) {
    //     e.preventDefault();
    //   }
    // } else {
    //   e.preventDefault();
    // }
    // console.log(e);
    if (e.type === 'paste') {
      key = e.clipboardData.getData('text/plain');
    } else {
      if(key != 8 && key != 9) {
        if(!e.key.match(regex)) {
          e.preventDefault();
        }
      }
    }
  },
  errors,
  errorsStyle,
  ...props
}) => {
  const titleDisable = disable ? styles.titleDisable : '';
  return (
    <div
      style={{ width: Width, margin: Margin }}
      className={`inputText input_Text ${classname_input} ${styles.inputText}`}
    >
      <label className={`inputTitle ${titleDisable}`} htmlFor={forLabel}>
        {inputTitle}
        <b hidden={hidden}>*</b>
      </label>
      
      <input
        title={titlePlaceholder}
        id={forLabel}
        autoComplete='off'
        disabled={disable}
        className='inputfield'
        type={type}
        value={value}
        defaultValue={defaultValue}
        // pattern="\d*"
        onKeyPress={e => {
          if ((e.target.value + '').length > maxLength) {
            e.preventDefault();
          }
        }}
        ref={register}
        maxLength={maxLength}
        onChange={e => {
          const newVal = e.target.value.replace(/^\s+/g, '');
          e.target.value = newVal;
          onChange(e);
        }}
        onKeyDown = {typeDetail === 'phone' ? onKeyDown : null }
        autoFocus={autoFocus}
        name={name}
        {...props}
      />
      {errors ? <div className={styles.error} style={errorsStyle}>{errors}</div> : null}
      <SanHead className='text' hidden={!disable}>
        {text}
      </SanHead>
    </div>
  );
};
