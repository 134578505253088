import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withLocalize } from 'react-localize-redux';
import { Helmet } from 'react-helmet';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BannerContent from '../../../components/BannerContent';
import Input from '../../../components/Input';
import { Modal } from 'antd';
import BreadCrumb from '../../../components/BreadCrumb';

import './style.scss';
const Flex = styled.div`
  display: flex;
`;

const CopyCertificateNumber = (props) => {
  const [visible, setVisible] = useState(false);
  const {history, translate, activeLanguage } = props;
  const scrollTop = () => {
    window.scrollTo({ top: 0 });
    document.getElementById('backtotopConfirmProcess').blur();
  };
  const handleKeyPress=(event) =>{
    if(event.key === 'Enter'){      
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  // let [code, setCode] = useState(null);
  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <div className="lr_background">
      <Helmet>
        <title>{translate('helmet.reportcopy')}</title>
      </Helmet>
      <BannerContent
        title={translate('reportCertification.secondUrl')}
        content={translate('reportCertification.bannerContent1')}
        background="/Images/bgr_certificate.png"
      />
      <div className="report_certificate">
        <BreadCrumb firstUrl={translate('reportCertification.firstUrl')} secondUrl={translate('reportCertification.secondUrl')} firstRouter="/report/guideline" secondRouter='/report/certificate' />
        <div className="rc_wrap">
          <div className="rcw_content">
            <div className="rcwc_detail">
              <div className={`rcwcd_title ${activeLanguage.code === 'en' ? 'title_en' : null}`}>
                {translate('reportCertification.title')}
              </div>
              <div className="rcwcd_input">
                <div className="rcwce_img">
                  <img src="/Images/Component8110.png" alt=''/>
                </div>
                <Input
                  forLabel={translate('reportCertification.titleField')}
                  disable
                  classname_input="search_box"
                  inputTitle={translate('reportCertification.titleField')}
                  hidden={false}
                  name="searchField"
                  value={sessionStorage.getItem('code')}
                />
                <CopyToClipboard text={sessionStorage.getItem('code')}>
                  <button
                    className="rcwce_btn_search"
                    onClick={() => {
                      setVisible(true);
                    }}
                    title={translate('TooltipReport.buttonCopy')}
                  >
                    <span>
                      {translate('reportCertification.buttonLabel')}
                    </span>
                  </button>
                </CopyToClipboard>
              </div>
              <div className="rcwcd_des">
                {activeLanguage.code === 'kr' ? (<span>※ <a href='/report/confirm-process'>[결과 확인]</a> 화면에서 처리결과를 확인하실 때에는 인증번호가 꼭 필요하오니 반드시 기억해두시기 바라며, <br/>인증번호는 제보시 입력하신 이메일로도 발송됩니다.</span>) : (<span>※ When checking the processing result on the <a href='/report/confirm-process'>[Confirmation of Result]</a> screen, an authentication number is required, <br/>so be sure to remember it, and the authentication number will also be sent to the email you entered when submitting the report.</span>)}
                {/* <span>
                  {translate('reportCertification.noteLabel')}
                </span> */}
              </div>
            </div>
            <div className="rcw_border">
              <div className="rcwc_list_item">
                <div className="rcwcl_item bgr_active">
                  <div className="bgr_item">
                    <img src="/Images/step1active.svg"
                      alt={translate('webAccessibility.step1ActiveAlt')}
                      title={translate('webAccessibility.step1ActiveAlt')}
                    />
                  </div>
                  <span>
                    <span>{translate('reportCertification.spanStep1')}</span>
                  </span>
                </div>
                <div className="rcwcl_item">
                  <div className="bgr_item">
                    <img
                      src="/Images/icreportnext11.svg"
                      alt={translate('webAccessibility.confirmStep2')}
                      title={translate('webAccessibility.confirmStep2')}
                    />
                  </div>
                  <span>
                    <span>{translate('reportCertification.spanStep2')}</span>
                  </span>
                </div>
                <div className="rcwcl_item">
                  <div className="bgr_item">
                    <img
                      src="/Images/icreportsearch11.svg"
                      alt={translate('webAccessibility.confirmStep3')}
                      title={translate('webAccessibility.confirmStep3')}
                    />
                  </div>
                  <span>
                    <span>{translate('reportCertification.spanStep3')}</span>
                  </span>
                </div>
                <div className="rcwcl_item">
                  <div className="bgr_item">
                    <img
                      src="/Images/icreportinactive.svg"
                      alt={translate('webAccessibility.confirmStep4')}
                      title={translate('webAccessibility.confirmStep4')}
                    />
                  </div>
                  <span>
                    <span>{translate('reportCertification.spanStep4')}</span>
                  </span>
                </div>
              </div>
              <div className='rcwc_content'>
                {translate('reportCertification.title2')}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Popup modal  */}
      <Modal
        className="modalAnony"
        visible={visible}
        footer={null}
        centered
        maskClosable
        width="522px"
        onCancel={e => {
          e.stopPropagation();
          setVisible(false);
        }}
        closeIcon={<img src="/Images/icclose36px.svg" alt={translate('webAccessibility.closeButton')} />}
      >
        <p className="content">
          {translate('reportCertification.contentModal')}
        </p>
        <button
          className="btn_click"
          onClick={() => {
            setVisible(false);
          }}
        >
          {translate('reportCertification.buttonModal')}
        </button>
      </Modal>
      <Flex className="btn_totop">
      <a href='javascript: void(0)' tabIndex="0" id='backtotopConfirmProcess' onClick={scrollTop} onKeyPress={handleKeyPress}>
        <img
          src="/Images/icarrowupward48px.svg"
          alt={translate('TooltipHome.btnToTop')}
          title={translate('TooltipHome.btnToTop')}
        />
        </a>
      </Flex>
    </div>
  );
};

export default withLocalize(CopyCertificateNumber);