import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import Management from '../../../components/DefaultContent/Philosophy/management';
import './style.scss';

class PhilosophyManagement extends Component {
  componentDidMount() {
    var activeTabDom = document.getElementsByClassName('ethical_tab_childrent');
    if (this.props.tabClick != '1') {
      activeTabDom[0].focus();
      activeTabDom[0].click();
      setTimeout(() => {
        document.getElementsByClassName('et_link')[0].focus();
      }, 200); 
    }
    document.getElementsByClassName('et_link')[0].setAttribute('title', this.props.activeLanguage.code === 'en' ? 'Selected' : '선택됨');
    document.getElementsByClassName('et_link')[1].removeAttribute('title');
    document.getElementsByClassName('et_link')[2].removeAttribute('title');
    activeTabDom[0].className = 'ethical_tab_childrent ethical_active';
    activeTabDom[1].className = 'ethical_tab_childrent ethical_inactive';
    activeTabDom[2].className = 'ethical_tab_childrent ethical_inactive';
    this.props.func('0');
  }
  render() {
    const { data, activeLanguage, translate } = this.props;
    const { code } = activeLanguage;
    if (data.length > 0) {
      return data.slice(0, 1).map((item, index) => {
        return (
          <div key={index} dangerouslySetInnerHTML={{ __html: item.content }}>
            {/* <div className="plcp_detail">
              <div className="plcp_title">{item.title}</div>
              {item.describe.split('\n').length > 1 ? (<div className="plcp_des des_margin">{item.describe.split('\n')[0]}</div>) : null}
              {item.describe.split('\n').length > 1 ? (<div className="plcp_des">{item.describe.substring(item.describe.split('\n')[0].length + 1, item.describe.length) }</div>) : <div className="plcp_des">{item.describe}</div>}
            </div>
            <div className="plcp_content">
              <div className="item_detail">
                <div
                  className="id_des"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></div>
              </div>
            </div> */}
          </div>
        );
      });
    } else {
      return <Management code={code} />;
    }
  }
}

export default withLocalize(PhilosophyManagement);
