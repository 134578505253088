import * as Type from '../types/ethicalManagement';

const initialState = {
  listContentProgess: [],
  loading: false,
};

const contentManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    // case Type.LOADING:
    //   return { ...state, loading: action.payload.loading };
    case Type.GET_ETHICAL_CONTENT_PROGRESS_SUCCESS:
      const content = action.payload.listContentProgress;
      content.sort((a, b) => {
        return Number(a.publicYear) - Number(b.publicYear);
      });
      return { ...state, listContentProgess: content,urlSites:action.urlSites, loading: true };
    case Type.GET_ETHICAL_CONTENT_PROGRESS_FAILURE:
      return { ...state, listContent: [], loading: true };
    default:
      return state;
  }
};

export default contentManagementReducer;
