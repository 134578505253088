import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
// import DatePicker from "../../../components/DatePickerInput";
// import ButtonLink from "../../../components/ButtonLink";
// import { getCategoryList } from "../../../services/api";
import CodeOfEthicsDefault from '../../../components/DefaultContent/EthicsStandard/CodeOfEthicsDefault';
import './style.scss';

class CodeOfEthics extends Component {
  state = {
    data: null,
    contentsList: null
  };

  componentDidMount() {    
    var activeTabDom = document.getElementsByClassName('ethics_children');
    if (this.props.tabClick != '1') {
      activeTabDom[0].focus();
      activeTabDom[0].click();
      setTimeout(() => {
        document.getElementsByClassName('et_link')[0].focus();
      }, 200);      
    }    
    document.getElementsByClassName('et_link')[0].setAttribute('title', this.props.activeLanguage.code === 'en' ? 'Selected' : '선택됨');
    document.getElementsByClassName('et_link')[1].removeAttribute('title');
    activeTabDom[0].className = 'ethics_children ethics_active';
    activeTabDom[1].className = 'ethics_children ethics_inactive';
    this.props.func('0');
  }
  render() {
    let { data } = this.props;
    if (data.length > 0) {
      return (
        data.slice(0, 1).map((item, index) => {
          return (
            <div className="CodeOf_Ethics" key={index} dangerouslySetInnerHTML={{__html: item.content}}>
              {/* <div className="ce_content_wrap">
                <div className="coe_content_detail">
                  <div className="coe_post_1">
                    <div className="post_detail">
                      <div className='pd-item'>
                      <div className="item_right_mobile">
                      <img src="/Images/A3.svg" alt="윤리헌장" title="윤리헌장" />
                    </div>
                      <h1 className="left_title">{item.title}</h1>
                      <div className='pdi_wrap'>
                      <div className="item_left">
                        <div className="left_content_1"                             
                        dangerouslySetInnerHTML={{__html: item.describe                                    
                          // .split('\n')
                          // .join('<br/>')
                          }}></div>
                      </div>
                      <div className="item_right">
                        <img src="/Images/A3.svg" alt=''/>
                      </div>
                      </div>
                      </div>
                    </div>
                    <div
                      className="coep_2"
                      dangerouslySetInnerHTML={{__html: item.content}}
                    ></div>
                  </div>
                </div>
              </div> */}
            </div>
          );
        })
      );
    } else {
      return (
        <CodeOfEthicsDefault lang = {this.props.activeLanguage.code}/>
      );
    }
  }
}
export default withLocalize(CodeOfEthics);
