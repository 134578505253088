import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { Helmet } from 'react-helmet';
import PhilosophyLayout from '../../hocs/PhilosophyLayout';
import CoreValues from './CoreValues';
import Vision from './Vision';
import PhilosophyManagement from './Management';
import { getContentList, getCategoryList } from '../../services/api';
import './style.scss';


let isTabClick = '1';
class Philosophy extends Component {
  state = {
    activeTab: 0,
    data: [],
    dataContent: [],
    id: null,
    historyTab: [],
    indexTab: 0,
    tabClick: ''
  };

  constructor(props) {
    super(props);
    const arrPath = window.location.pathname.split('/');
    this.id = arrPath.length > 2 ? arrPath[2] : null;
  }

  componentDidMount() {
    this.loadContent(this.props);
    this.setState({
      historyTab: [0]
    });
    
    // window.addEventListener('popstate', this.setActiveTab);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLanguage.code !== nextProps.activeLanguage.code) {
      this.loadContent(nextProps);
    }
  }

  loadContent = async props => {
    await getContentList('PHILOSOPHY', props.activeLanguage.code).then(
      response => {
        const { data } = response;
        if (data && data.data) {
          this.setState({
            data: data.data.listCategories[0].children
          });
          const arrPath = window.location.pathname.split('/');
          this.id = arrPath.length > 2 ? arrPath[2] : null;
          if (!this.id) {
            this.props.history.push(
              `/philosophy/${
                data.data.listCategories[0].children[this.state.activeTab].id
              }`
            );
          }
          getCategoryList(
            data.data.listCategories[0].children[this.state.activeTab].id,
            props.activeLanguage.code
          ).then(resCat => {
            const content = resCat.data.data.contentsList;
            this.setState({
              dataContent: content
            });
            
          });
        } else {
          this.setState({
            data: []
          });
        }
      }
    );
  };
  fetchData = () => {
    return getContentList('PHILOSOPHY', this.props.activeLanguage.code);
  };
  getdata = (id, lang) => {
    return getCategoryList(id, lang);
  };
  func = (value) => {
    isTabClick = value;
    this.setState({tabClick: value});
  }
  render() {
    const { translate } = this.props;
    const { path } = this.props.match;
    const { data, dataContent } = this.state;
    return (
      <PhilosophyLayout>
        <Helmet>
          <title>{translate('helmet.philosophy')}</title>
        </Helmet>
        <div className="philosophy">
          <div className="philosophy_tab_wrap">
            <div className="ethical_tab">
              {data &&
                data.map((item, index) => {
                  return (
                    <Link to={`${path}/${item.id}`} className="et_link" key={index} >
                      <div
                        className={`ethical_tab_childrent ${
                          this.id === item.id
                            ? 'ethical_active'
                            : 'ethical_inactive'
                          }`}
                        onClick={() => {
                          // let historyTab = this.state.historyTab;
                          // let indexTab = this.state.indexTab + 1;
                          // historyTab.push(index);
                          // isTabClick = index + 1;
                          // this.setState({
                          //   activeTab: index,
                          //   historyTab: historyTab,
                          //   indexTab: indexTab,
                          //   tabClick: index + 1
                          // });
                          this.getdata(
                            item.id,
                            this.props.activeLanguage.code
                          ).then(response => {
                            this.setState({
                              dataContent: response.data.data.contentsList
                            });
                          });
                        }}
                      >
                        <span className='title'>{item.name}</span>
                        {/* <Link to={`${path}`}>{item.name}</Link> */}
                        {/* <div className="arrowBtn"></div> */}
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>
        <Switch>
          {data &&
            data.map((item, index) => (
              <Route
                key={index}
                path={`${path}/${item.id}`}
                render={() => {
                  if (item.templateCd === 'P1') {
                    return <PhilosophyManagement data={dataContent} tabClick = {isTabClick } func = {value => this.func(value)}/>;
                  } else if (item.templateCd === 'P2') {
                    return <CoreValues data={dataContent} tabClick = {isTabClick } func = {value => this.func(value)} />;
                  } else if (item.templateCd === 'P3') {
                    return <Vision data={dataContent} tabClick = {isTabClick } func = {value => this.func(value)} />;
                  }
                }}
              />
            ))}
        </Switch>
        {/* {
          this.state.activeTab === 0 ? <PhilosophyManagement data={dataContent} />
            : this.state.activeTab === 1 ? <CoreValues data={dataContent} /> : <Vision data={dataContent} />
        } */}
      </PhilosophyLayout>
    );
  }
}

export default withLocalize(Philosophy);
