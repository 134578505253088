import { Modal } from 'antd';
import React, { useState } from 'react';
import './style.scss';

const PrivacyPolicyPop = () => {
  const [showPopup, setshowPopup] = useState(true);

  return (
	<div>
		<div class="p5-content">
			<Modal 
				onRequestClose={() => setshowPopup(false)} 
				title="개인정보 처리방침 공지"
				onOk={() => setshowPopup(false)}
				onCancel={() => setshowPopup(false)}
				visible={showPopup}>

				<div class="pppContent">
					<p>안녕하십니까, 현대자동차그룹 사이버감사실입니다.<br />
						사이버감사실 개인정보 처리방침이 아래와 같이 변경됨을 알려드립니다.
					</p><br/>

					<p>▶ 개정 주요 내용</p>
					<table>
						<thead>
							<tr>
								<td>
									<p>조항</p>
								</td>
								<td valign="top">
									<p>수정 전</p>
								</td>
								<td valign="top">
									<p>수정 후</p>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td valign="top">
									<p>2. 개인정보의 처리 목적, 항목 및 보유기간</p>
								</td>
								<td valign="top">
									<p>신규 추가</p>
								</td>
								<td valign="top">
									<p>1) 법령에 따른 개인정보 보유 및 이용기간 또는 제보자로부터 개인정보를 수집 시에 동의 받은 개인정보 보유, 이용기간 내에서 개인정보를 처리, 보유합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적, 항목이 변경되는 경우에는 ｢개인정보 보호법｣ 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
								</td>
							</tr>
							<tr>
								<td valign="top">
									<p>3. 개인정보의 파기절차 및 파기방법</p>
								</td>
								<td valign="top">
									<p>내용 추가</p>
								</td>
								<td valign="top">
									<p>3) 개인정보 파기의 절차<br />
										파기 사유가 발생한 개인정보를 선정하거나, 개인정보의 이용 목적 달성 또는 보유 및 이용 기간이 종료되면 해당 정보를 즉시 파기하며, 기타 관련 법령에 의해 보관하는 경우, 해당 기간 보관 후 즉시 파기합니다
									</p>
								</td>
							</tr>
							<tr>
								<td valign="top">
									<p>4. 개인정보 처리의 위탁</p>
								</td>
								<td valign="top">
									<p>신규 추가</p>
								</td>
								<td valign="top">
									<p>1) 위탁업체 상세<br/> - 재수탁업체 : ㈜노스스타컨설팅, ㈜아이파트너즈<br/>- 재위탁 업무 내용 : 시스템 유지/보수 개발</p>
									<p>2) 회사는 위탁계약 체결 시 ｢개인정보 보호법｣ 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적&middot;관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리&middot;감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.</p>
									<p>3) 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</p>
								</td>
							</tr>
							<tr>
								<td valign="top">
									<p>5. 개인정보의 제3자 제공</p>
								</td>
								<td valign="top">
									<p>내용 추가-</p>
								</td>
								<td valign="top">
									<p>현대자동차 그룹사(제공받는 자) 상세 표기</p>
								</td>
							</tr>
							<tr>
								<td valign="top">
									<p>8. 제보자, 법정대리인의 권리와 의무 및 그 행사방법</p>
								</td>
								<td valign="top">
									<p>신규 추가</p>
								</td>
								<td valign="top">
									<p>4) 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제4항, 제37조 제2항에 의하여 제보자의 권리가 제한될 수 있습니다.</p>
									<p>5) 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</p>
									<p>6) 회사는 정보주체 권리에 따른 열람의 요구, 정정, 삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</p>
								</td>
							</tr>
							<tr>
								<td valign="top">
									<p>9. 제보자의 권익침해에 대한 구제방법</p>
								</td>
								<td valign="top">
									<p>-</p>
								</td>
								<td valign="top">
									<p>개인정보 분쟁조정위원회 정보 추가</p>
								</td>
							</tr>
							<tr>
								<td valign="top">
									<p>10. 개인정보 보호책임자 및 담당자, 업무처리 부서</p>
								</td>
								<td  valign="top">
									<p>- 개인정보 보호책임자 : 이충연 책임매니저</p>
								</td>
								<td valign="top">
									<p>- 개인정보 보호책임자 : 김진성 실장</p>
									<p>- 소속 : 감사실</p>
									<p>- 개인정보보호 담당자 : 신민재 책임매니저</p>
									<p>- 소속 : 감사기획팀</p>
								</td>
							</tr>
						</tbody>
					</table>
					<p>※ 자세한 사항은 개정된 사이버감사실 개인정보 처리방침을 참조하시길 바랍니다.</p>
					<p>▶ 변경 적용일 : 2024년 4월 30일</p>
					<p>감사합니다.</p>
				</div>
			</Modal>
		</div>
	</div>
  );
};

export default PrivacyPolicyPop;