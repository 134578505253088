import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FaqPost from '../../components/FaqPost';
import BannerContent from '../../components/BannerContent';
import { listContentFaq } from '../../store/actions/faq';
//import { Pagination } from 'antd';
import Pagination from 'react-js-pagination';
import './style.scss';

class Faq extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      typeCode: 'FAQ',
    };
  }
  componentDidMount() {
    const { typeCode, page, pageSize } = this.state;
    this.props.listContentFaqFunc(typeCode, page, pageSize);
  }

  renderContent = (listFaq, page) => {
    if (listFaq.length > 0) {
      return listFaq.map((el, index) => (
        // eslint-disable-next-line react/jsx-key
        <div key={index} className="faq-post-item">
          <FaqPost faq={el} index={index} page={page}  />
        </div>
      ));
    }
    return '';
  }

  handleChangePage = current_page => {
    const { typeCode, pageSize } = this.state;
    this.props.listContentFaqFunc(typeCode, current_page, pageSize);
    this.setState({ page: current_page });
  }

  render() {
    const { listContentFaq, totalRecord } = this.props;
    const { page, pageSize } = this.state;

    return (
      <div className="faq-container">
        <BannerContent title="FAQ" content="" background="/Images/Group31161.png" />
        <div className="faq-body">
          <div className="faq-nav">
            <div className="faq-nav-content">
              <img className="home-icon" src="/Images/ichome48px.svg" />
              <img src="/Images/ickeyboardarrowright24px.svg" alt="" />
              <p>FAQ</p>
            </div>
          </div>
          <div className="faq-content-ct">
            <div className="faq-content">
              <h2 className="faq-title">총 질문 ({totalRecord})</h2>
              {this.renderContent(listContentFaq, page)}
            </div>
          </div>
          <div className="pagination">
            <Pagination
              pageRangeDisplayed={5}
              activePage={page}
              itemsCountPerPage={pageSize}
              totalItemsCount={totalRecord}
              onChange={this.handleChangePage}
              firstPageText={
                <div className="btn-start">
                  <img src="/Images/ickeyboardarrowright24px.svg" alt="" />
                  <img src="/Images/ickeyboardarrowright24px.svg" alt="" />
                </div>
              }
              lastPageText={
                <div className="btn-end">
                  <img src="/Images/ickeyboardarrowright24px.svg" alt="" />
                  <img src="/Images/ickeyboardarrowright24px.svg" alt="" />
                </div>
              }
              prevPageText={<div className="btn-previous"><img src="/Images/ickeyboardarrowright24px.svg" alt="" /></div>}
              nextPageText={<img src="/Images/ickeyboardarrowright24px.svg" alt="" />}
            />            
          </div>
        </div>
      </div>
    );
  }
}

// export default Faq;
const mapStateToProps = (state) => {
  const data = state.faqReducer;
  return {
    listContentFaq: data.listContentFaq,
    isLoading: data.loading,
    totalRecord: data.totalRecord,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listContentFaqFunc: (typeCode, page, pageSize) => dispatch(listContentFaq(typeCode, page, pageSize)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Faq));