import React, { Component } from "react";

class CodeOfEthicsDefault extends Component {
  render() {
    if (this.props.lang === "kr") {
      return (
        <div className="CodeOf_Ethics">
          <div className="ce_content_wrap">
            <div className="coe_content_detail">
              <div className="coe_post_1">
                <div className="post_detail">
                  <h1 className="hTagHidden">윤리헌장</h1>
                  <div className="pd-item">
                    <div className="item_right_mobile">
                      <img
                        src="/Images/A3.svg"
                        alt="윤리헌장"
                        title="윤리헌장"
                      />
                    </div>
                    <h1 className="left_title">
                      윤리헌장 및 실천규범의 적용대상은kkk
                    </h1>
                    <div className="pdi_wrap">
                      <div className="item_left">
                        <div className="left_content_1">
                          현대자동차그룹 전 계열회사로서 국내·외 생산 및
                          판매법인, 자회사 및 손자회사, 합작투자사(
                          <span className="lc_lang" lang="en">
                            Joint Venture
                          </span>
                          )를 포함하며, 현대자동차그룹 임직원은 공급자 및
                          판매·서비스 조직을 대할 때에도 본 윤리헌장 및
                          실천규범에 따라 행동한다. 본 윤리헌장 및 실천규범이
                          적용되는 조직의 모든 임직원은 해당 국가의 법규를
                          준수하는 동시에 본 윤리헌장 및 실천규범에 따라 업무를
                          수행해야 한다. 더 나아가 당사와 거래관계에 있는 모든
                          이해관계자도 본 윤리강령 및 실천규범을 존중할 수
                          있도록 권장하여야 한다.
                        </div>
                      </div>
                      <div className="item_right">
                        <img
                          src="/Images/A3.svg"
                          alt="윤리헌장"
                          title="윤리헌장"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="left_content_2">
                    윤리헌장 및 실천규범이 적용되는 회사는 본 윤리헌장 및
                    실천규범을 기반으로 하는 규정, 정책, 지침 등에 따라 업무를
                    수행해야 한다. 본 윤리헌장 및 실천규범, 관련 규정, 정책,
                    지침 등이 현지 국가의 법규와 상충되는 경우에는 현지 법규를
                    우선적으로 준수하고, 해당 국가에서 요구하는 법규 및 산업
                    특성을 반영하여 본 윤리헌장 및 실천규범을 개정하여 사용할 수
                    있으며, 필요시 별도의 세부 정책을 수립할 수 있다. 본
                    윤리헌장 및 실천규범을 도입하고자 하는 조직은
                    현대자동차그룹의 사전 허가를 얻어야 한다.
                  </div>
                </div>
                <div className="coep_2">
                  <div className="post_detail_1">
                    <div className="post_title">
                      현대자동차그룹 5대 윤리헌장
                    </div>
                    <div className="post_description des_margin">
                      1. 우리는 명확하고 투명한 기준을 갖고 업무를 수행하며,
                      성실과 최선으로 주어진 책임을 다한다.
                    </div>
                    <div className="post_description">
                      2. 우리는 시장에서 정정당당하게 경쟁하며, 계약관계에 있는
                      상대방과 공정하게 거래한다.
                    </div>
                    <div className="post_description">
                      3. 우리는 고객가치 실현을 위해 안전한 제품과 최상의
                      서비스, 올바른 정보를 제공하고 개인정보를 철저히 보호한다.
                    </div>
                    <div className="post_description">
                      4. 우리는 구성원 개개인을 독립된 인격체로서 존중하고, 이를
                      위해 공정한 근로조건과 안전한 근무환경을 제공한다.
                    </div>
                    <div className="post_description">
                      5. 우리는 글로벌 기업시민으로서 사회적 책임 이행을 통해
                      다양한 이해관계자가 함께 번영할 수 있는 지속가능한 발전을
                      구현하는데 기여한다.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="CodeOf_Ethics">
          <div className="ce_content_wrap">
            <div className="coe_content_detail">
              <div className="coe_post_1">
                <div className="post_detail">
                  <h1 className="hTagHidden">Ethics Charter</h1>
                  <div className="pd-item">
                    <div className="item_right_mobile">
                      <img
                        src="/Images/A3.svg"
                        alt="윤리헌장"
                        title="윤리헌장"
                      />
                    </div>
                    <h1 className="left_title">
                      Ethics Charter and Code of Conduct
                    </h1>
                    <div className="pdi_wrap">
                      <div className="item_left">
                        <div className="left_content_1">
                          Applies to all affiliates of Hyundai Motor Group,
                          including production and sales corporate bodies at
                          home and abroad, subsidiaries, second-tier
                          subsidiaries, and joint ventures, and the executives
                          and employees of Hyundai Motor Group should act in
                          accordance with this Ethics Charter and Code of
                          Conduct when dealing with suppliers and sales/service
                          organizations. Executives and employees who work for
                          organizations covered under the scope of this Ethics
                          Charter and Code of Conduct should comply with local
                          laws and perform their duties in accordance with the
                          Ethics Charter and Code of Conduct. Moreover, all
                          stakeholders holding contractual relationships with
                          Hyundai Motor Group, hereinafter referred to as the
                          “Company”, should respect the content and spirit of
                          this Ethics Charter and Code of Conduct.
                        </div>
                      </div>
                      <div className="item_right">
                        <img
                          src="/Images/A3.svg"
                          alt="윤리헌장"
                          title="윤리헌장"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="left_content_2">
                    All persons and organizations who fall under the scope of
                    this document shall perform their duties in accordance with
                    the regulations, policies and rules that guide this Ethics
                    Charter and Code of Conduct. Where this Ethics Charter and
                    Code of Conduct, and related regulations, policies and rules
                    contradict local laws, the local laws shall prevail. In such
                    cases, the Company may amend this document to reflect local
                    legal and industrial obligations, and if necessary, may
                    specifically establish detailed policies in response. Any
                    organizations that wish to adopt this Ethics Charter and
                    Code of Conduct should obtain prior consent from the
                    Company.
                  </div>
                </div>
                <div className="coep_2">
                  <div className="post_detail_1">
                    <div className="post_title">
                      Five Guiding Principles at Hyundai Motor Group
                    </div>
                    <div className="post_description des_margin">
                      1. We shall perform our duties based on clear and
                      transparent standards, and do our utmost to fulfill our
                      responsibilities with integrity.
                    </div>
                    <div className="post_description">
                      2. We shall compete fairly in the market and conduct
                      business ethically with parties that engage in contractual
                      relationships with us.
                    </div>
                    <div className="post_description">
                      3. We shall provide safe products, exceptional services
                      and accurate information, and we rigorously protect
                      personal information to increase customer value.
                    </div>
                    <div className="post_description">
                      4. We shall respect our members as independent
                      individuals, and to this end, we provide fair working
                      conditions and safe working environments.
                    </div>
                    <div className="post_description">
                      5. We shall contribute to sustainable development by
                      fulfilling our social & environmental responsibilities as
                      a member of society, so that diverse stakeholders may
                      prosper together in harmony.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default CodeOfEthicsDefault;
