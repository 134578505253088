import * as Type from '../types/faq';

const initialState = {
  listContentFaq: [],
  loading: false,
  totalRecord: 0,
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.GET_CONTENT_FAQ_SUCCESS:
      const content = action.payload.listFaq || [];
      return { ...state, listContentFaq: content, totalRecord: action.payload.total, loading: true };
    case Type.GET_CONTENT_FAQ_FAILURE:
      return { ...state, listContentFaq: [], loading: true };
    default:
      return state;
  }
};

export default faqReducer;
