import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import styles from "./style.module.scss";
class ButtonLink extends Component {
  render() {
    const { text, TextLink, onClick, onKeyPress, title } = this.props;
    const Wrap = styled.div``;
    const openNewTab = () => {
      window.open(TextLink, "_blank");
    };
    return (
      <Wrap onClick={onClick} onKeyPress={onKeyPress} title={title}>
        {this.props.href ? (
          <a
            className={styles.linkBtn}
            href={this.props.href}
            target="_blank"
            tabindex="0"
            download
          >
            {text}
          </a>
        ) : (
          <a
            className={styles.linkBtn}
            href={TextLink}
            target="_blank"
            tabindex="0"
            download
          >
            {text}
          </a>
        )}
      </Wrap>
    );
  }
}

export default ButtonLink;
