import React, { Component } from "react";
import ButtonLink from "../../../components/ButtonLink";
import { savePDF } from "@progress/kendo-react-pdf";
import SelectBoxTitle from "../../../components/SelectBoxTitle/index";
import { withLocalize } from "react-localize-redux";
import moment from "moment";
// import { API_SERVER_FILE } from '../../../constants/index';
class CodeOfConductDefault extends Component {
  state = {
    btnActive: null,
    selectBoxActive: null
  };

  createPdf = html => {
    savePDF(html, {
      paperSize: "Letter",
      fileName: "EthicsStandard.pdf",
      margin: 3
    });
  };
  onDownloadPDF = href => {
    return window.open(href, "_blank");
  };
  render() {
    const dateFormat =
      this.props.activeLanguage.code === "en" ? "DD-MMM-YYYY" : "YYYY-MM-DD";
    let bodyRef = React.createRef();
    const { btnActive, selectBoxActive } = this.state;
    if (this.props.lang === "kr") {
      return (
        <div className="CodeOf_Conduct">
          <div className="ce_content_wrap">
            <div className="coe_content_detail">
              <div className="right_date">
                <SelectBoxTitle
                  labelTagTitle={this.props.translate(
                    "webAccessibility.labelTagYear"
                  )}
                  title={this.props.translate(
                    "webAccessibility.selectboxEthics"
                  )}
                  suffixIcon="/Images/arrow.png"
                  style={{ Width: "80%" }}
                  textvalue="개정 이력 보기"
                  forLabel="개정 이력 보기"
                  class_selectbox={`dateSelectBox ${selectBoxActive}`}
                  titleSelect={this.props.translate(
                    "webAccessibility.selectYear"
                  )}
                  defaultValue={"2020-05-31"}
                  data={[]}
                  onChange={this.onSelectedValue}
                  key="kr"
                  // remove box-shadow when clicked
                  onDropdownVisibleChange={() => {
                    this.setState({ selectBoxActive: "selectBoxActive" });
                  }}
                  onFocus={() => {
                    this.setState({ selectBoxActive: "" });
                  }}
                />
                <ButtonLink
                  title="임직원 윤리실천강령 PDF 다운로드"
                  text="PDF"
                  // href={'/Ebook/Ethics_Standard_Kr.pdf'}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.onDownloadPDF("/Ebook/Ethics_Standard_Kr.pdf");
                      this.setState({ btnActive: "btn-active" });
                    }
                  }}
                  onClick={() => {
                    this.onDownloadPDF("/Ebook/Ethics_Standard_Kr.pdf");
                    this.setState({ btnActive: "btn-active" });
                  }}
                />
              </div>
              <div className="coe_post3">
                <div className="item_right_mobile">
                  <img src="/Images/A3.svg" alt="윤리헌장" title="윤리헌장" />
                </div>
                <div className="EthicsStandard" ref={bodyRef}>
                  <div className="code_of_ethics_wrap">
                    <div className="post_detail_3">
                      <h1 className="hTagHidden">실천규범</h1>
                      <div className="pd_content">
                        <h1 className="left_title">
                          윤리헌장 및 실천규범의 적용대상은
                        </h1>
                        <div className="pdi_wrap">
                          <div className="item_left">
                            <div className="left_content_1">
                              현대자동차그룹 전 계열회사로서 국내·외 생산 및
                              판매법인, 자회사 및 손자회사, 합작투자사(
                              <span className="lc_lang" lang="en">
                                Joint Venture
                              </span>
                              )를 포함하며, 현대자동차그룹 임직원은 공급자 및
                              판매·서비스 조직을 대할 때에도 본 윤리헌장 및
                              실천규범에 따라 행동한다. 본 윤리헌장 및
                              실천규범이 적용되는 조직의 모든 임직원은 해당
                              국가의 법규를 준수하는 동시에 본 윤리헌장 및
                              실천규범에 따라 업무를 수행해야 한다. 더 나아가
                              당사와 거래관계에 있는 모든 이해관계자도 본
                              윤리강령 및 실천규범을 존중할 수 있도록 권장하여야
                              한다.
                            </div>
                          </div>
                          <div className="item_right">
                            <img
                              className="i_image"
                              src="/Images/A3.svg"
                              alt="임직원 윤리실천강령"
                              title="임직원 윤리실천강령"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="left_content_2">
                        윤리헌장 및 실천규범이 적용되는 회사는 본 윤리헌장 및
                        실천규범을 기반으로 하는 규정, 정책, 지침 등에 따라
                        업무를 수행해야 한다. 본 윤리헌장 및 실천규범, 관련
                        규정, 정책, 지침 등이 현지 국가의 법규와 상충되는
                        경우에는 현지 법규를 우선적으로 준수하고, 해당 국가에서
                        요구하는 법규 및 산업 특성을 반영하여 본 윤리헌장 및
                        실천규범을 개정하여 사용할 수 있으며, 필요시 별도의 세부
                        정책을 수립할 수 있다. 본 윤리헌장 및 실천규범을
                        도입하고자 하는 조직은 현대자동차그룹의 사전 허가를
                        얻어야 한다.
                      </div>
                    </div>
                    <div className="post_content_3" tabIndex="0">
                      {/* content */}
                      <div className="post_title">1. 임직원 윤리</div>
                      <div className="post_description des_margin des_highlight">
                        우리는 명확하고 투명한 기준을 갖고 업무를 수행하며,
                        성실과 최선으로 주어진 책임을 다한다.
                      </div>
                      <div className="post_description">
                        <span className="pd_title">뇌물</span>
                        <div className="pd_text">
                          금전, 비금전적 어떠한 형태의 불법적, 비윤리적 이익이나
                          뇌물<sup style={{ fontSize: "13px" }}>1 </sup>을
                          이해관계자로부터 수수하거나, 제공 또는 제공의 약속을
                          하지 않는다.
                        </div>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">청탁</span>
                        <span className="pd_text">
                          임직원과 이해관계자 간 또는 내부 임직원 간 부정한
                          청탁을 금지한다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">이해관계 상충</span>
                        <span className="pd_text">
                          회사와 개인의 이해관계 상충의 회피가 불가피한 경우,
                          합법적인 범위 내에서 회사와 고객의 이익을 우선적으로
                          고려하여 행동한다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">내부자 거래</span>
                        <div className="pd_text">
                          직무수행을 통해 직·간접적으로 획득한 기밀, 지식, 정보
                          <sup style={{ fontSize: "13px" }}>2 </sup>를
                          누설하거나, 개인의 이익을 추구하는데 활용하지 않아야
                          한다.
                        </div>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">직장윤리</span>
                        <span className="pd_text">
                          임직원은 시간 또는 공간에 관계없이 회사의 업무를
                          수행하는 동안 개인에게 주어진 책임을 다해야 하며,
                          회사의 자산 및 시설은 업무 목적으로만 사용해야 한다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">직권남용</span>
                        <span className="pd_text">
                          직무권한 또는 지위를 활용하여 부당한 영향력을
                          행사하거나, 회사 또는 개인의 불법적 이익을 추구하지
                          않는다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">문서작성 및 보고</span>
                        <span className="pd_text">
                          은폐, 축소, 과장, 허위의 내용 등 조작된 내용을
                          기반으로 문서를 작성 또는 보고하거나, 내·외부
                          이해관계자와 공유하지 않는다.
                        </span>
                      </div>
                      <div className="post_description post_description_explain">
                        {/* <span className="pd_title">
                          1
                        </span> */}
                        <span className="pd_text_explain">
                          1 뇌물은 금전 또는 비금전적 목적을 획득하기 위해
                          제공하거나 제공받는 모든 형태의 이익을 말하며, 금전,
                          서비스, 접대, 선물, 기부금, 지원금, 우대조치, 편의
                          제공 등을 포함한다.
                        </span>
                        <span className="pd_text_explain">
                          2 내부자 거래에는 흔히 회사의 기밀, 지식, 영업정보,
                          인수합병과 같은 조직정보, 주가 관련 정보, 내부
                          의사결정사항 등이 활용되는 경우가 많다.
                        </span>
                      </div>
                      <div className="post_description">
                        {/* <span className="pd_title">
                          2
                        </span> */}
                      </div>
                      <div className="post_title">2. 공정한 경쟁과 거래</div>
                      <div className="post_description des_margin des_highlight">
                        우리는 시장에서 정정당당하게 경쟁하며, 계약관계에 있는
                        상대방과 공정하게 거래한다.
                      </div>
                      <div className="post_description">
                        <span className="pd_title">반독점</span>
                        <span className="pd_text">
                          시장지배적지위를 남용하거나, 거래상 지위를 남용하는 등
                          불공정한 거래 등을 통해 공정한 경쟁을 저해할 우려가
                          있는 행위를 하지 않는다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">담합</span>
                        <span className="pd_text">
                          상품 또는 용역의 가격, 공급량, 거래지역, 거래조건 등에
                          관해 다른 사업자와 부당하게 경쟁을 제한하는 행위를 할
                          것을 합의하지 않는다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">부정경쟁</span>
                        <span className="pd_text">
                          경쟁업체, 협력업체, 또는 타 기관으로부터 부정한
                          방법으로 정보를 획득하지 않으며, 회사 또는 제3자가
                          부정하게 획득한 정보를 사용하거나 공개하지 않는다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">자금세탁</span>
                        <span className="pd_text">
                          고객, 파트너, 협력업체, 기타 기관 및 개인의 자금세탁과
                          관련되는 행위를 하지 않는다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">지식재산권</span>
                        <span className="pd_text">
                          계약관계에 있는 협력사의 영업비밀을 보호하고, 타사나
                          타인의 지식재산권을 침해하지 않는다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">조세</span>
                        <span className="pd_text">
                          투명하고 공정한 조세사무처리를 위해 사업장이 위치한 각
                          국의 세법을 준수하고, 주어진 정당한 납세의무를
                          회피하지 않는다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">조달</span>
                        <span className="pd_text">
                          협력업체와 공정한 상생관계를 추구하며, 부당한 거래
                          행위를 하지 않는다.
                        </span>
                      </div>
                      {/* content */}
                      <div className="post_title">3. 고객가치 실현</div>
                      <div className="post_description des_margin des_highlight">
                        우리는 고객가치 실현을 위해 안전한 제품과 최상의 서비스,
                        올바른 정보를 제공하고 개인정보를 철저히 보호한다.
                      </div>
                      <div className="post_description">
                        <span className="pd_title">고객 안전</span>
                        <span className="pd_text">
                          연구개발, 원자재 조달, 생산, 판매 및 유통, 판매 후
                          서비스 전 과정에서 고객의 안전과 타협하는 의사결정을
                          하지 않는다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">품질</span>
                        <span className="pd_text">
                          고객에게 최상의 제품과 서비스를 안정적으로 공급하기
                          위해 필요한 품질 기준을 저해하는 행위를 하지 않는다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">완전한 정보제공</span>
                        <span className="pd_text">
                          제품 및 서비스와 관련된 바르고 유용한 정보를 고객에게
                          제공하여 올바른 의사결정을 할 수 있도록 도우며, 허위
                          및 과장된 정보를 제공하지 않는다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">개인정보 보호</span>
                        <span className="pd_text">
                          고객의 개인정보보호를 위해 관련 법규와 규정을
                          준수하며, 고객의 개인정보를 위법하게 침해할 수 있는
                          행위를 하지 않는다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">고객의견 수렴</span>
                        <span className="pd_text">
                          제품 및 서비스에 대한 고객의 의견을 경청하고, 고객의
                          정당한 요구와 합리적 제안은 적극 수용한다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">고객 접근성</span>
                        <span className="pd_text">
                          성별, 나이, 장애, 언어 등을 이유로 제품 및 서비스의
                          사용을 부당하게 제약 받는 고객이 없도록 필요한 조치를
                          취한다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">제조물 책임</span>
                        <span className="pd_text">
                          고객의 피해와 위험을 방지하기 위하여 필요한 정보를
                          적극적으로 제공하고, 제품과 서비스의 안전과 품질
                          확보를 위한 조치를 책임감 있게 이행한다.
                        </span>
                      </div>
                      {/* content */}
                      <div className="post_title">4. 임직원 존중</div>
                      <div className="post_description des_margin des_highlight">
                        우리는 구성원 개개인을 독립된 인격체로서 존중하고, 이를
                        위해 공정한 근로조건과 안전한 근무환경을 제공한다.
                      </div>
                      <div className="post_description">
                        <span className="pd_title">인권</span>
                        <span className="pd_text">
                          임직원 개개인을 독립된 인격체로 존중하고, 인류사회가
                          보편적으로 추구하는 인권이 보호 받을 수 있도록
                          적극적인 조치를 취한다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">아동노동 및 강제노동</span>
                        <span className="pd_text">
                          사업장이 위치한 국가의 노동법을 준수하며, 사업장 내
                          아동노동 및 강제노동을 금지한다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">차별</span>
                        <span className="pd_text">
                          국적, 출신지역, 인종, 성별, 연령, 문화, 종교, 장애,
                          학력, 정치적 성향 또는 개인적 취향 등을 이유로
                          임직원을 차별하지 않는다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">동등한 기회</span>
                        <span className="pd_text">
                          능력과 자질에 따라 임직원들에게 동등한 교육과 성장의
                          기회를 부여하며, 성과에 대해 공정하게 평가하고
                          보상한다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">직장 괴롭힘</span>
                        <span className="pd_text">
                          언어적 또는 신체적 폭력, 성희롱, 따돌림, 협박과 같이
                          구성원의 인격을 모독하거나 인간의 존엄을 훼손하는 온 ·
                          오프라인 상의 모든 행위를 금지한다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">안전 및 보건</span>
                        <span className="pd_text">
                          임직원의 안전을 위해 필요한 조치를 적극적으로 취하며,
                          직무상 사고 및 부상, 재난, 재해, 질병 및 전염으로부터
                          안전한 작업환경을 유지한다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">일 · 가정 양립</span>
                        <span className="pd_text">
                          임직원의 일과 삶이 조화롭게 균형을 이루는 업무환경을
                          제공하도록 노력해야 한다.
                        </span>
                      </div>
                      {/* content */}
                      <div className="post_title">5. 지속가능성 추구</div>
                      <div className="post_description des_margin des_highlight">
                        우리는 글로벌 기업시민으로서 사회적 책임 이행을 통해
                        다양한 이해관계자가 함께 번영할 수 있는 지속가능한
                        발전을 구현하는데 기여한다.
                      </div>
                      <div className="post_description">
                        <span className="pd_title">지속가능한 발전</span>
                        <span className="pd_text">
                          경영의사결정 과정에서 인류사회가 직면한 환경적, 사회적
                          문제를 해결하기 위한 지속가능한발전 목표(SDG) 달성에
                          기여하는 책임을 다한다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">환경</span>
                        <span className="pd_text">
                          환경은 다음 세대를 위하여 보전하여야 할 소중한
                          자산임을 인지하고, 사업 전 영역에서의 환경에 대한
                          부정적인 영향을 최소화하기 위한 책임을 적극 이행한다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">사회공헌</span>
                        <span className="pd_text">
                          우리 사회, 나아가 인류가 당면한 사회적 문제를 적극
                          발굴하고, 해결 방안을 적극 모색한다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">이해관계자 참여</span>
                        <span className="pd_text">
                          회사 경영에 영향을 받거나, 회사에 영향을 미칠 수 있는
                          이해관계자들을 파악하여 지속가능성 관련 중요 사안에
                          대해 적극적으로 소통하고 행동한다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">기부 및 후원</span>
                        <span className="pd_text">
                          자선적인 기부 및 후원은 내부 집행기준과 절차에 따라
                          공정하게 추진하며, 정치적 목적의 기부 및 후원을
                          금지한다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">주주가치</span>
                        <span className="pd_text">
                          지속가능경영을 통하여 회사와 주주의 가치 증진을
                          추구한다.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">정보공개</span>
                        <span className="pd_text">
                          재무적, 비재무적 정보는 관련 법규와 규정에 따라 적시에
                          공개한다.
                        </span>
                      </div>
                      <div className="post_title post_title_appendix_top">
                        부록
                      </div>
                      <div className="post_description">
                        <span className="pd_title post_title_appendix">
                          1. 참고문헌
                        </span>
                        <span className="post_description des_margin des_highlight">
                          본 윤리강령 및 실천규범은 여러 국제 인권규범 및 관련
                          법규에서 명시하는 정신과 가치를 추구하며, 아래의 선언
                          및 협약을 기반으로 제정하였다.
                        </span>
                        <span className="pd_text pd_appendix" lang="en">
                          ① Universal Declaration of Human Rights(세계인권선언),
                          UN General Assembly, 1948.
                        </span>
                        <span className="pd_text pd_appendix" lang="en">
                          ② The Ten Principles of the UN Global Compact, UN
                          Global Compact
                        </span>
                        <span className="pd_text pd_appendix" lang="en">
                          ③ International Labour Organization’s Declaration on
                          Fundamental Principles and Rights at Work, ILO (1998).
                        </span>
                        <span className="pd_text pd_appendix" lang="en">
                          ④ Convention on Combating Bribery of Foreign Public
                          Officials in International Business Transactions, OECD
                          (2019).
                        </span>
                        <span className="pd_text pd_appendix" lang="en">
                          ⑤ United Nations Convention Against Corruption, UN
                          General Assembly (2013).
                        </span>
                        <span className="pd_text pd_appendix" lang="en">
                          ⑥ Paris Agreement, UNFCCC (2015).
                        </span>
                        <span className="pd_text pd_appendix" lang="en">
                          ⑦ The OECD Guidelines for Multinational Enterprises,
                          OECD (2011).
                        </span>
                        <span className="pd_text pd_appendix" lang="en">
                          ⑧ Global Automotive Sustainability Practical Guidance,
                          Drive Sustainability (2017).
                        </span>
                        <span className="pd_text pd_appendix" lang="en">
                          ⑨ Guiding Principles on Business and Human Rights,
                          UNGC (2011).
                        </span>
                        <span className="pd_text pd_appendix" lang="en">
                          ⑩ Guidance on social responsibility, ISO (2010).
                        </span>
                        <span className="pd_text pd_appendix" lang="en">
                          ⑪ UN SDGs (https://sustainabledevelopment.un.org/)
                        </span>
                        <span className="pd_text pd_appendix" lang="en">
                          ⑫ CDP (https://www.cdp.net/en)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="CodeOf_Conduct">
          <div className="ce_content_wrap">
            <div className="coe_content_detail">
              <div className="right_date">
                {/* <SelectBoxTitle
                  labelTagTitle={this.props.translate('webAccessibility.labelTagYear')}
                  title={this.props.translate(
                    "webAccessibility.selectboxEthics"
                  )}
                  suffixIcon="/Images/arrow.png"
                  style={{ Width: "80%" }}
                  textvalue="View revision history"
                  forLabel="View revision history"
                  class_selectbox={`dateSelectBox ${selectBoxActive}`}
                  defaultValue={"31-May-2020"}
                  data={[]}
                  onChange={this.onSelectedValue}
                  titleSelect={this.props.translate('webAccessibility.selectYear')}
                  key="kr"
                  // remove box-shadow when clicked
                  onDropdownVisibleChange={() => {
                    this.setState({ selectBoxActive: "selectBoxActive" });
                  }}
                  onFocus={() => {
                    this.setState({ selectBoxActive: "" });
                  }}
                /> */}
                <ButtonLink
                  title="임직원 윤리실천강령 PDF 다운로드"
                  text="PDF"
                  // href={'/Ebook/Ethics_Standard_Kr.pdf'}
                  onClick={() => {
                    this.onDownloadPDF("/Ebook/Ethics_Standard_En.pdf");
                    this.setState({ btnActive: "btn-active" });
                  }}
                />
                <ButtonLink
                  title="임직원 윤리실천강령 PDF 다운로드"
                  text="PDF"
                  // href={'/Ebook/Ethics_Standard_Kr.pdf'}
                  onClick={() => {
                    this.onDownloadPDF("/Ebook/Ethics_Standard_En.pdf");
                    this.setState({ btnActive: "btn-active" });
                  }}
                />
              </div>
              <div className="coe_post3">
                <div className="item_right_mobile">
                  <img src="/Images/A3.svg" alt="윤리헌장" title="윤리헌장" />
                </div>
                <div className="EthicsStandard" ref={bodyRef}>
                  <div className="code_of_ethics_wrap">
                    <div className="post_detail_3">
                      <h1 className="hTagHidden">Code of Conduct</h1>
                      <div className="pd_content">
                        <h1 className="left_title">
                          Ethics Charter and Code of Conduct
                        </h1>
                        <div className="pdi_wrap">
                          <div className="item_left">
                            <div className="left_content_1">
                              Applies to all affiliates of Hyundai Motor Group,
                              including production and sales corporate bodies at
                              home and abroad, subsidiaries, second-tier
                              subsidiaries, and joint ventures, and the
                              executives and employees of Hyundai Motor Group
                              should act in accordance with this Ethics Charter
                              and Code of Conduct when dealing with suppliers
                              and sales/service organizations. Executives and
                              employees who work for organizations covered under
                              the scope of this Ethics Charter and Code of
                              Conduct should comply with local laws and perform
                              their duties in accordance with the Ethics Charter
                              and Code of Conduct. Moreover, all stakeholders
                              holding contractual relationships with Hyundai
                              Motor Group, hereinafter referred to as the
                              “Company”, should respect the content and spirit
                              of this Ethics Charter and Code of Conduct.
                            </div>
                          </div>
                          <div className="item_right">
                            <img
                              className="i_image"
                              src="/Images/A3.svg"
                              alt="임직원 윤리실천강령"
                              title="임직원 윤리실천강령"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="left_content_2">
                        All persons and organizations who fall under the scope
                        of this document shall perform their duties in
                        accordance with the regulations, policies and rules that
                        guide this Ethics Charter and Code of Conduct. Where
                        this Ethics Charter and Code of Conduct, and related
                        regulations, policies and rules contradict local laws,
                        the local laws shall prevail. In such cases, the Company
                        may amend this document to reflect local legal and
                        industrial obligations, and if necessary, may
                        specifically establish detailed policies in response.
                        Any organizations that wish to adopt this Ethics Charter
                        and Code of Conduct should obtain prior consent from the
                        Company.
                      </div>
                    </div>
                    <div className="post_content_3" tabIndex="0">
                      {/* content */}
                      <div className="post_title">
                        1. Ethics for Executives and Employees
                      </div>
                      <div className="post_description des_margin des_highlight">
                        We shall perform our duties based on clear and
                        transparent standards, and do our utmost to fulfill our
                        responsibilities with integrity.
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Bribery</span>
                        <div className="pd_text">
                          We shall not receive any form, monetary or
                          non-monetary, of illegal or unethical benefits and
                          bribery<sup style={{ fontSize: "13px" }}>1</sup> from
                          our stakeholders, nor do we provide or promise
                          thereof.
                        </div>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Improper Solicitation</span>
                        <span className="pd_text">
                          We prohibit improper solicitation externally between
                          executive and employees and stakeholders, as well as
                          internally between executives and employees.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Conflicts of Interest</span>
                        <span className="pd_text">
                          In cases where conflicts of interests between the
                          Company and individuals are unavoidable, we act in the
                          best interests of the Company and our customers,
                          within the scope allowed by the law.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Insider Trading</span>
                        <div className="pd_text">
                          We shall not leak insider secrets, knowledge or
                          information<sup style={{ fontSize: "13px" }}>2</sup>{" "}
                          obtained either directly or indirectly in the course
                          of employment, nor use such illicit gains for the
                          purpose of pursuing individual interest.
                        </div>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Workplace Ethics</span>
                        <span className="pd_text">
                          Executives and employees shall do their utmost to
                          fulfill their responsibilities in the course of
                          employment, regardless of time or place, and use the
                          Company’s assets and facilities only for the purposes
                          of fulfilling their duties.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Abuse of Power</span>
                        <span className="pd_text">
                          We shall not abuse our power or status to exert undue
                          influence or pursue illicit benefits for the Company
                          or individuals.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">
                          Documentation & Reporting
                        </span>
                        <span className="pd_text">
                          We shall not create misleading documents by hiding,
                          reducing, exaggerating or falsifying information, nor
                          shall we report such documents or share them with
                          internal and external stakeholders.
                        </span>
                      </div>
                      <div className="post_description post_description_explain">
                        {/* <span className="pd_title">
                          1
                        </span> */}
                        <span className="pd_text_explain">
                          1 Bribery refers to all forms of benefits that provide
                          or are provided for monetary or non-monetary purposes,
                          including money, services, entertainment, gifts,
                          donations, sponsorship, preferential treatment,
                          provision of conveniences, and etc.
                        </span>
                        <span className="pd_text_explain">
                          2 Insider trading involves in abusing a company’s
                          secrets, knowledge, sales information, organizational
                          information such as a merger and acquisition, stock
                          price information, and internal decision-making.
                        </span>
                      </div>
                      {/* <div className="post_description"> */}
                      {/* <span className="pd_title">
                          2
                        </span> */}
                      {/* </div> */}
                      {/* content */}
                      <div className="post_title">
                        2. Fair Competition and Trade
                      </div>
                      <div className="post_description des_margin des_highlight">
                        We shall compete in the market fairly and conduct
                        business ethically with parties that engage in
                        contractual relationships with us.
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Anti-trust</span>
                        <span className="pd_text">
                          We shall not engage in activities that would disrupt
                          fair competition through the pursuit of unfair
                          transactions, such as abusing our market dominance or
                          trading position.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Collusion</span>
                        <span className="pd_text">
                          We shall not engage in activities with other
                          businesses that would restrict fair competition in
                          terms of the prices, volume, area and terms of
                          products and services, including, but not limited to,
                          price fixing, bid rigging, or improper market
                          allocation.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Unfair Competition</span>
                        <span className="pd_text">
                          We shall not improperly obtain information from
                          competitors, partners, or any other organizations, nor
                          use or disclose information obtained illicitly by the
                          Company or third parties.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Money Laundering</span>
                        <span className="pd_text">
                          We shall not engage in money-laundering activities
                          involving customers, partners, suppliers, or other
                          organizations and individuals.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Intellectual Property</span>
                        <span className="pd_text">
                          We shall protect the trade secrets of suppliers in
                          engaged in contractual relationships with the Company,
                          and shall not infringe on the intellectual property
                          rights of other companies or individuals.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Tax Compliance</span>
                        <span className="pd_text">
                          For transparent and fair compliance with our tax
                          obligations, we shall comply with the tax laws of each
                          and every country where we have business operations,
                          and shall not avoid legitimate tax obligations.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Procurement</span>
                        <span className="pd_text">
                          We shall seek fair and mutually beneficial
                          relationships with our suppliers, and will not engage
                          in unfair competition.
                        </span>
                      </div>
                      {/* content */}
                      <div className="post_title">
                        3. Realization of Customer Value
                      </div>
                      <div className="post_description des_margin des_highlight">
                        We shall provide safe products, exceptional services and
                        accurate information, and we rigorously protect personal
                        information to increase customer value.
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Customer Safety</span>
                        <span className="pd_text">
                          We shall not make decisions that compromise customer
                          safety, covering all processes from research and
                          development, to raw material procurement,
                          manufacturing, sales and distribution, and after-sales
                          service.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">
                          Product/Service Quality
                        </span>
                        <span className="pd_text">
                          We shall not engage in any activities that would
                          compromise the quality standards necessary to provide
                          our customers with exceptional quality products and
                          services in a stable manner.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Complete Information</span>
                        <span className="pd_text">
                          We shall help our customers make informed decisions by
                          providing them with correct and useful information
                          regarding our products and services, and shall not
                          provide falsified or exaggerated information.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Privacy Protection</span>
                        <span className="pd_text">
                          We shall comply with the laws and regulations
                          applicable to protecting customers’ personal
                          information, and shall not engage in activities that
                          would illicitly infringe upon their rights to data
                          privacy.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Customer Communication</span>
                        <span className="pd_text">
                          We shall listen carefully to customers’ feedback on
                          our products and services, and are committed to
                          accepting reasonable demands and recommendations in
                          this respect.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">
                          Product/Service Accessibility{" "}
                        </span>
                        <span className="pd_text">
                          We shall take any appropriate measures necessary to
                          prevent discrimination against customers who are
                          treated unfairly in terms of access to our products
                          and services based on gender, age, disability,
                          language, etc.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Product Liability</span>
                        <span className="pd_text">
                          We are committed to providing any information
                          necessary to prevent damages and risks to customers,
                          and shall implement appropriate measures to ensure the
                          safety and quality of our products and services.
                        </span>
                      </div>
                      {/* content */}
                      <div className="post_title">
                        4. Respect for Executives and Employees
                      </div>
                      <div className="post_description des_margin des_highlight">
                        We shall respect our members as independent individuals,
                        and to this end, we provide fair working conditions and
                        safe working environments.
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Human Rights</span>
                        <span className="pd_text">
                          We respect executives and employees as independent
                          individuals, and shall take affirmative action to
                          respect the human rights universally pursed by
                          society.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">
                          Child Labor and Forced Labor{" "}
                        </span>
                        <span className="pd_text">
                          We shall comply with the labor laws of the countries
                          where we have business operations, and ban child labor
                          and forced labor in workplace.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Discrimination</span>
                        <span className="pd_text">
                          We forbid discrimination against executives and
                          employees based on nationality, place of birth, race,
                          gender, age, culture, religion, disability, education,
                          political affiliation, personal tastes, or any other
                          factors.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Equal Opportunity</span>
                        <span className="pd_text">
                          We shall provide executives and employees with equal
                          opportunities for training and capacity development,
                          assess them fairly based on performance, and give them
                          fair compensation.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Harassment</span>
                        <span className="pd_text">
                          We ban any and all activities, online and offline,
                          that may insult or damage human dignity, such as
                          verbal abuse, sexual harassment, mental or physical
                          coercion, corporal punishment, ostracization, and
                          threats.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Safety and Health</span>
                        <span className="pd_text">
                          We are committed to taking all measures necessary to
                          ensure the safety and well-being of our executives and
                          employees, and to maintain working environments safe
                          from occupational accidents, injuries, disasters,
                          disease and contagion.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Work-Life Balance</span>
                        <span className="pd_text">
                          We shall endeavor to provide a working environment
                          that promotes work-life balance for our executives and
                          employees.
                        </span>
                      </div>
                      {/* content */}
                      <div className="post_title">
                        5. Commitment to Sustainability
                      </div>
                      <div className="post_description des_margin des_highlight">
                        We shall contribute to sustainable development by
                        fulfilling our social & environmental responsibilities
                        as a member of society, so that diverse stakeholders may
                        prosper together in harmony.
                      </div>
                      <div className="post_description">
                        <span className="pd_title">
                          Sustainable Development
                        </span>
                        <span className="pd_text">
                          Through our management decision-making processes, we
                          shall endeavor to fulfill our responsibilities
                          concerning the sustainable development goals (SDGs),
                          with the aim of ending the environmental and social
                          problems facing humanity.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Environment</span>
                        <span className="pd_text">
                          We understand that the environment is an invaluable
                          asset to be preserved for future generations, and are
                          committed to implementing responsible actions to
                          minimize any negative impacts on the environment in
                          all management areas.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Philanthropy</span>
                        <span className="pd_text">
                          We are committed to identifying the social problems
                          facing our society and humanity, and to finding
                          solutions for these issues.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">
                          Stakeholder Engagement{" "}
                        </span>
                        <span className="pd_text">
                          By proactively identifying stakeholders who may
                          impact, or be impacted by, the company’s
                          decision-making, we are committed to communicating and
                          taking action with regard to important matters
                          concerning sustainability.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">
                          Contribution and Sponsorship
                        </span>
                        <span className="pd_text">
                          We shall comply with all internal standards and rules
                          relevant to fairly processing charitable donations and
                          sponsorships, and ban donations and sponsorships of a
                          political nature.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Shareholder Value</span>
                        <span className="pd_text">
                          We shall promote corporate and shareholder value by
                          implementing sustainable management practices.
                        </span>
                      </div>
                      <div className="post_description">
                        <span className="pd_title">Information Disclosure</span>
                        <span className="pd_text">
                          We shall make financial and non-financial information
                          publicly available, in accordance with all applicable
                          laws and regulations.
                        </span>
                      </div>
                      <div className="post_title post_title_appendix_top">
                        Appendix
                      </div>
                      <div className="post_description">
                        <span className="pd_title post_title_appendix">
                          1. Reference
                        </span>
                        <span className="post_description des_margin des_highlight">
                          This Ethics Charter and Practice Guideline follows the
                          spirits and values defined by international norms and
                          applicable laws concerning human rights, and is
                          enacted based on the following international
                          declarations and agreements.
                        </span>
                        <span className="pd_text pd_appendix">
                          ① Universal Declaration of Human Rights, UN General
                          Assembly, 1948.
                        </span>
                        <span className="pd_text pd_appendix">
                          ② The Ten Principles of the UN Global Compact, UN
                          Global Compact
                        </span>
                        <span className="pd_text pd_appendix">
                          ③ International Labour Organization’s Declaration on
                          Fundamental Principles and Rights at Work, ILO (1998).
                        </span>
                        <span className="pd_text pd_appendix">
                          ④ Convention on Combating Bribery of Foreign Public
                          Officials in International Business Transactions, OECD
                          (2019).
                        </span>
                        <span className="pd_text pd_appendix">
                          ⑤ United Nations Convention Against Corruption, UN
                          General Assembly (2013).
                        </span>
                        <span className="pd_text pd_appendix">
                          ⑥ Paris Agreement, UNFCCC (2015).
                        </span>
                        <span className="pd_text pd_appendix">
                          ⑦ The OECD Guidelines for Multinational Enterprises,
                          OECD (2011).
                        </span>
                        <span className="pd_text pd_appendix">
                          ⑧ Global Automotive Sustainability Practical Guidance,
                          Drive Sustainability (2017).
                        </span>
                        <span className="pd_text pd_appendix">
                          ⑨ Guiding Principles on Business and Human Rights,
                          UNGC (2011).
                        </span>
                        <span className="pd_text pd_appendix">
                          ⑩ Guidance on social responsibility, ISO (2010).
                        </span>
                        <span className="pd_text pd_appendix">
                          ⑪ UN SDGs (https://sustainabledevelopment.un.org/)
                        </span>
                        <span className="pd_text pd_appendix">
                          ⑫ CDP (https://www.cdp.net/en)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withLocalize(CodeOfConductDefault);
