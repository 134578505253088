import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import styled from 'styled-components';

const Logo = styled.h1`
  width: 20%;
  @media screen and (max-width: 1199px) {
    width: 60%;
    text-align: center;
  }
  a{
    display: inline-block;
    //margin-left:35px;
    @media screen and (max-width: 1199px) {
      margin-left: 0;
    }
    @media screen and (max-width: 576px) {
      margin-left: 0;
    }
      &:focus{
        box-shadow: 0 0 2px 2px #51a7e8;
      }
      @media screen and (max-width: 576px) {
        &:focus{
          box-shadow: none;
        }
      }
    img{
      width: 87px;
    }
  }
`;
class LogoHyundaiAudit extends Component {
  render() {
    const { translate } = this.props;
    return (
      <Logo style={{ position: 'relative', zIndex: '99' }}>
        <Link to='/' title={translate('webAccessibility.titleReport')}>
          <img src='/Images/logo.png' alt={translate('webAccessibility.logoReport')} tabIndex="-1"/>
        </Link>
      </Logo>
    );
  }
}

export default withLocalize(LogoHyundaiAudit);
