import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import TranslateLanguage from '../TranslateLanguage';

import styles from './style.module.scss';

const Wrap = styled.div``;
const Wrap1200 = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;
const Flex = styled.div`
  display: flex;
`;

const Footer = ({ translate, activeLanguage }) => {
  let [width, setWidth]= useState(null);

  useEffect(() => {
    if(window.innerWidth <= 768){
      setWidth(true);
    }
    else {
      setWidth(false);
    }
  },[window.innerWidth]);

  const onDownloadPDF = href => {
    return window.open(href, '_blank');
  };
  
  const { code } = activeLanguage;
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Wrap className={`hae_footer_component ${styles.footer_component}`}>
      <Wrap1200 className={styles.fc_Wrap}>
        <Flex className={styles.fcw_wrap}>
          <Flex className={styles.fcww_left}>
            <Wrap className={styles.fcwwl_Logo}>
              <img src="/Images/hyundaimotorgroupci.svg" alt={translate('TooltipHome.logo')} />
            </Wrap>
            <Flex className={styles.fcwwl_policy}>
              <Flex className={styles.fp_top}>
                <Link to="/site-map" title={translate('webAccessibility.siteMap')}>{translate('global.site_map')}</Link>
                <Link to="/privacy-policy" className={styles.pp_font_bold} title={translate('webAccessibility.privacy')} onClick={scrollTop}>{translate('global.privacy_policy')}</Link>
                {code === 'en' ? null :(
                <Link className={styles.wa_mark_link} title='새창열림'                   
                onClick={() => {
                  onDownloadPDF('/Ebook/webaccessibility.pdf');
                }}>
                  <span>한국 웹접근성 인증</span>
                  <img className={styles.wa_mark} src="/Images/wa_mark_2022.png" alt='(사)한국장애인단체총연합회 한국웹접근성인증평가원 웹접근성 우수사이트 인증마크(WA인증마크)' />
                </Link>
                )}
              </Flex>
              <Flex className={styles.fp_bottom}>
                <Flex className={`${styles.fpb_top} ${styles.fpb_item1}`}>
                  <img src="/Images/iclocationon48px.svg" alt="" />
                  <span>{translate('global.location')}</span>
                </Flex>
                <div className={`${styles.fpb_flex} ${code === 'en' ? styles.fpb_hidden : null}`}>
                  <Flex className={`${styles.fpb_top} ${styles.fpb_item2}`}>
                    <img
                      src="/Images/icphone48px.svg"
                      alt=""
                    />
                    <span><span className={width ? null : `${styles.fpbi_mobile}`} href={width ? 'tel:02-3464-3500' : null} >02-3464-3500</span></span>
                  </Flex>
                  <Flex className={`${styles.fpb_top} ${styles.fpb_item3}`}>
                    <img src="/Images/nounfax89515.svg" alt="" />
                    <span>02-3464-8813</span>
                  </Flex>
                </div>
              </Flex>
            </Flex>
          </Flex>

          <Flex className={`${styles.item} ${styles.fcww_right}`}>
            <Flex className={`${styles.bottom} ${styles.fcwwr_social}`}>
              <div className={styles.s_margin}>
                <a href="https://www.youtube.com/user/HyundaiLive" target="_blank" rel="noopener noreferrer" title={translate('TooltipHome.aTagiconFooter')}>
                  <img src="/Images/youtube.svg" alt={translate('TooltipHome.iconYoutube')} tabIndex="-1" />
                </a>
                <a href="https://twitter.com/hmg_talk" target="_blank" rel="noopener noreferrer" title={translate('TooltipHome.aTagiconFooter')}>
                  <img src="/Images/twitter.svg" alt={translate('TooltipHome.iconTwitter')} tabIndex="-1"  />
                </a>
                <a href="https://www.facebook.com/hyundaimotorgroup" target="_blank" rel="noopener noreferrer" title={translate('TooltipHome.aTagiconFooter')}>
                  <img src="/Images/facebook.svg" alt={translate('TooltipHome.iconFacebook')} tabIndex="-1"  />
                </a>
                <a href="https://www.instagram.com/hyundaimotorgroup.official/" target="_blank" rel="noopener noreferrer" title={translate('TooltipHome.aTagiconFooter')}>
                  <img src="/Images/instagram.svg" alt={translate('TooltipHome.iconInsta')} tabIndex="-1"  />
                </a>
              </div>
             
              <div className={styles.boxChan} >
                {/* <SelectBox style={{ Width: "90%" }}
                  defaultValue={defaultLanguage}
                  data={languageOptions}
                  onChange={onLanguageChange}
                /> */}
                <TranslateLanguage />
                
              </div>
             
              
            </Flex>

            <Wrap className={styles.fcwwr_textBottom}>
              <Wrap className={styles.footerLogo2}>
                <img src="/Images/hyundaimotorgroupci.svg" alt={translate('TooltipHome.logo')} />
              </Wrap>
              <span className={styles.text} lang='en'>
                COPYRIGHT ⓒ HYUNDAI MOTOR GROUP. ALL RIGHTS RESERVED.
                </span>
            </Wrap>
          </Flex>
        </Flex>
      </Wrap1200>
    </Wrap>
  );
};

export default withLocalize(Footer);
