import globalTranslations from './global';
import ReactDOMServer from 'react-dom/server';

const initializeLanguage = {
  languages: [
    { name: 'KOR', code: 'kr' },
    { name: 'ENG (中文)', code: 'en' }
  ],
  translation: globalTranslations,
  options: {
    renderToStaticMarkup: ReactDOMServer.renderToStaticMarkup,
    // defaultLanguage: 'kr',
    defaultLanguage: localStorage.getItem("languageCode") ? localStorage.getItem("languageCode") : 'kr'
  }
};

export default initializeLanguage;
