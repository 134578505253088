import { put, all, takeEvery, call } from 'redux-saga/effects';
import { apiListContentFaq } from '../../services/api/index';
import * as Type from '../types/faq';

async function asyncAPI(api, data) {
  return await api(data);
}

export function* apiGetListContentFaq(action) {
  const { typeCode, page, pageSize } = action.payload;
  try {
    const param = { typeCode, page, pageSize };
    const res = yield call(asyncAPI, apiListContentFaq, param);
    if (res.data && res.data.data) {
      const content = res.data.data ? res.data.data : [];
      yield put({ type: Type.GET_CONTENT_FAQ_SUCCESS, payload: { listFaq: content, total: res.data.totalRecord } });
    }
  } catch (e) {
    yield put({ type: Type.GET_CONTENT_FAQ_FAILURE, payload: e.message });
  }
}

/**
 * Make a root saga
 *
 * @returns {object} - Generator functions all combined in one saga
 */

export default function* rootSaga() {
  yield all([
    takeEvery(Type.GET_CONTENT_FAQ, apiGetListContentFaq),
  ]);

}
