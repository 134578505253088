import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LayoutEthics from '../../hocs/LayoutEthics';
import CodeOfEthics from './CodeOfEthics';
import CodeOfConduct from './CodeOfConduct';
import { getCategoryList, getContentList } from '../../services/api';
import './style.scss';

const storeHistory = () => { };
let isTabClick = '1';
class EthicsStandard extends Component {
  state = {
    activeTab: 0,
    data: [],
    dataContent: [],
    id: null,
    historyTab: [],
    indexTab: 0,
    tabClick: ''
  };
  constructor(props) {
    super(props);
    const arrPath = window.location.pathname.split('/');
    this.id = arrPath.length > 2 ? arrPath[2] : null;
  }
  componentDidMount() {
    this.loadContent(this.props);
    this.setState({
      historyTab: [0]
    });
    // window.addEventListener('popstate', this.setActiveTab);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.activeLanguage.code !== nextProps.activeLanguage.code) {
      this.loadContent(nextProps);
    }
  }

  loadContent = async props => {
    await getContentList('ETHICALS', props.activeLanguage.code).then(
      response => {
        const { data } = response;
        if (data && data.data) {
          const arrPath = window.location.pathname.split('/');
          this.id = arrPath.length > 2 ? arrPath[2] : null;
          if (!this.id) {
            this.props.history.push(
              `/ethics-standard/${data.data.listCategories[0].children[this.state.activeTab].id}`
            );
          }
          getCategoryList(
            data.data.listCategories[0].children[this.state.activeTab].id,
            props.activeLanguage.code
          ).then(resCat => {
            const content = resCat.data.data.contentsList;
            this.setState({
              data: data.data.listCategories[0].children,
              dataContent: content
            });
          });
        } else {
          this.setState({
            data: []
          });
        }
      }
    );
  };
  fetchData = () => {
    return getContentList('ETHICALS', this.props.activeLanguage.code);
  };
  getdata = (id, lang) => {
    return getCategoryList(id, lang);
  };
  func = (value) => {
    isTabClick = value;
    this.setState({tabClick: value});
  }
  render() {
    const { translate } = this.props;
    const { path } = this.props.match;
    const { data, dataContent } = this.state;
    return (
      <LayoutEthics>
        <Helmet>
          <title>{translate('helmet.ethics')}</title>
        </Helmet>
        <div className="ethics_management_wrap">
          <div className="ce_content"></div>
          <div className="ethics_tab_wrap">
            <div className="ethics_tab">
              {data.map((item, index) => {
                return (
                  <Link to={`${path}/${item.id}`} className="et_link" key={index} title={item.name}>
                    {/* <Link to={`${path}`} className="et_link" key> */}
                    <div
                    //  tabIndex="-1"
                      // key={index}
                      className={`ethics_children ${
                        this.id === item.id
                          ? 'ethics_active'
                          : 'ethics_inactive'
                        }`}
                      onClick={() => {
                        // let historyTab = this.state.historyTab;
                        // let indexTab = this.state.indexTab + 1;
                        // historyTab.push(index);
                        // isTabClick = index + 1
                        // this.setState({
                        //   activeTab: index,
                        //   historyTab: historyTab,
                        //   indexTab: indexTab,
                        //   tabClick: index + 1
                        // });
                        this.getdata(
                          item.id,
                          this.props.activeLanguage.code
                        ).then(response => {
                          this.setState({
                            dataContent: response.data.data.contentsList
                          });
                        });
                      }}
                    >
                      <span className='title'>{item.name}</span>
                      {/* <Link to={`${path}`}>{item.name}</Link> */}
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
        <Switch>
          {data.map((item, index) => (
            <Route
              key={index}
              path={`${path}/${item.id}`}
              render={() => {
                if (item.templateCd === 'S1') {
                  return <CodeOfEthics data={dataContent} tabClick = {isTabClick } func = {value => this.func(value)} />;
                } else if (item.templateCd === 'S2') {
                  return <CodeOfConduct data={dataContent} tabClick = {isTabClick} func = {value => this.func(value)}/>;
                }
              }}
            />
          ))}
        </Switch>
        {/* {this.state.activeTab === 0 ? (
          <CodeOfEthics data={dataContent} />
        ) : (
            <CodeOfConduct data={dataContent} />
          )} */}
      </LayoutEthics>
    );
  }
}

export default withLocalize(withRouter(EthicsStandard, storeHistory));
