import React from "react";

export default code => {
  if (code.code === "kr") {
    return (
      <div>
        <h1 className="hTagHidden">경영철학</h1>
        <div className="plcp_detail">
          <h1 className="plcp_title">경영철학 핵심개념</h1>
          <div className="plcp_des des_margin">
            창의적 사고와 끝없는 도전을 통해 새로운 미래를 창조함으로써
            인류사회의 꿈을 실현합니다.
          </div>
          <div className="plcp_des">
            창의적 사고와 끝없는 도전을 통해 새로운 미래를 창조함으로써
            인류사회의 꿈을 실현합니다. 현대자동차그룹은 글로벌 리딩 기업의
            책임과 역할을 잘 알고 있습니다. 다양한 이해관계자의 믿을 수 있는
            파트너가 되고, 고객의 가치를 높일 수 있도록 기업 정신의 근간을
            마련했습니다. 현대자동차그룹은 누구도 생각지 못한 원대한 목표에
            도전하고 앞선 생각으로 시장을 선도하며, 모든 인류가 행복해질 수
            있도록 책임의식을 발휘하여 미래 사회의 동반자가 될 것을 약속합니다.
          </div>
        </div>
        <div className="plcp_content">
          <div className="item_detail">
            <img
              src="/Images/Group31801.svg"
              className="image img_margin"
              alt=""
            />
            <h1 className="id_title">무한책임정신</h1>
            <div className="id_des">
              {/* 무한책임정신은 현대자동차그룹의 성장과 발전의 밑거름이 됩니다.
              그룹 전체 구성원과 협력사 직원들에 대한 책임의식은 글로벌 성공의
              밑거름이 되었고, 고객의 안전과 행복에 대한 무한의 책임정신은
              품질경영으로 구현되어져 왔습니다. 그룹 구성원과 고객을 향한 책임은
              함께 사는 사회에 대한 책임으로 나아갑니다. 모두의 삶을 더욱
              윤택하게 하기 위해 고품질 및 고객만족을 기본으로 한 글로벌 확장을
              추진하고, 쇳물에서 완성차로 이어지는 친환경 자원순환 구조를
              실현하고 있습니다. 현대자동차그룹의 무한책임정신은 모두를 위한
              무한가치 창조로 이어질 것입니다. */}
            </div>
          </div>

          <div className="item_detail item_border">
            <img
              src="/Images/Group31813_new.svg"
              className="image"
              alt=""
            />
            <h1 className="id_title">가능성의 실현</h1>
            <div className="id_des">
              {/* 현대자동차그룹은 이미 도달한 목표점에서 안주하지 않습니다. 실패의
              위험이 있다 해도 포기하지 않고, 새로운 목표를 향해 도전하여
              가능성을 현실로 만드는 자신감과 신념을 지니고 있기 때문입니다.
              대부분의 사람들이 불가능에 가깝다고 여긴 것들에 우리는 도전해왔고
              또 이를 성공시켰습니다. 북미 자동차 시장 진출, 자동차 엔진 독자
              개발, 국내 최초 민간 자본 제철소인 일관제철소 건립, 세계 유일 원전
              10기 동시 시공, 시속 400km급 고속철도 자체 개발 등
              현대자동차그룹이 이룩하는 가능성의 실현에는 최초와 최대 그리고
              최고가 함께할 것입니다. 지난 과거의 성공에 안주하지 않고 더 큰
              미래를 창조할 수 있도록 끝없이 도전하겠습니다. */}
            </div>
          </div>

          <div className="item_detail item_border">
            <img
              src="/Images/Group31802.svg"
              className="image"
              alt=""
            />
            <h1 className="id_title">인류애의 구현</h1>
            <div className="id_des">
              {/* 기업이 존재하는 궁극적인 가치는 무엇일까요? 안전하고 편리한
              자동차도, 빠른 열차도, 최신 기술과 첨단 빌딩도 사람이 없다면
              의미를 지니지 못할 것입니다. 무한책임정신을 근간으로 가능성의
              실현을 위해 끊임없이 도전해 온 현대자동차그룹의 꿈은 ‘인류애의
              구현’이라는 더 크고 높은 곳을 향해 있습니다. 현대자동차그룹은 더
              좋은 제품과 서비스를 더 많은 사람에게, 더 신속하게 제공하여 인류의
              생활수준을 높이는 데 기여하고자 합니다. 또한 전 세계 고객에게
              존경받는 글로벌 기업이 되기 위해 한 차원 높은 가치를 제공하고,
              기업과 사람과 사회의 상생을 통해 인류사회의 꿈을 실현할 수 있도록
              지속적으로 노력하겠습니다. */}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h1 className="hTagHidden">Philosophy</h1>
        <div className="plcp_detail">
          <h1 className="plcp_title">
            Key elements of our Management Philosophy
          </h1>
          <div className="plcp_des des_margin">
            Realize the dream of mankind by creating a new future through
            ingenious thinking and continuously challenging new frontiers.
          </div>
          <div className="plcp_des">
            Hyundai Motor Group promotes creative ideas and overcomes numerous
            challenges to create a better future and realize dreams of humanity.
            In this journey, the Group assumes important roles and
            responsibilities to lead the global business, being a trustworthy
            partner for diverse stakeholders and building a corporate philosophy
            to raise customer value. Hence, Hyundai Motor Group will set
            ambitious new goals, lead the market with forward-thinking, and
            fulfill its responsibility to achieve prosperity for all.
          </div>
        </div>
        <div className="plcp_content">
          <div className="item_detail">
            <img
              src="/Images/Group31801.svg"
              className="image img_margin"
              alt="Philosophy. Unlimited Sense of Responsibility"
              title="Philosophy. Unlimited Sense of Responsibility"
            ></img>
            <h1 className="id_title">Unlimited Sense of Responsibility</h1>
            <div className="id_des">
              An unlimited sense of responsibility is the basis for our growth
              and advancement. Our responsibility toward the members of the
              Group as well as partner companies has motivated us to become a
              stronger company, and our responsibility for customers’ safety and
              happiness has led to a passion for better quality. Our sense of
              responsibility goes beyond our members and customers to contribute
              to society at large. To enrich the lives of all people, Hyundai
              Motor Group has gone global on the strength of excellent quality
              and customer satisfaction, and built an environment-friendly
              resource circulating business structure. Our unbounded sense of
              responsibility will continue to create infinite value for all.
            </div>
          </div>

          <div className="item_detail item_border">
            <img
              src="/Images/Group31813_new.svg"
              className="image"
              alt="Philosophy. Realization of Possibilities"
              title="Philosophy. Realization of Possibilities"
            ></img>
            <h1 className="id_title">Realization of Possibilities</h1>
            <div className="id_des">
              Hyundai Motor Group is not complacent about its achievements. The
              risk of failure is no deterrent to our pursuit of new challenges,
              because we believe in ourselves and have the confidence to turn
              possibilities into realities. Hyundai Motor Group has a long
              history of challenging and achieving the seemingly impossible. Our
              entry into the North American automotive market, development of
              proprietary car engines, construction of the first
              private-sector-built integrated steelworks in Korea, the world’s
              first simultaneous construction of 10 nuclear power generation
              units, and development of a high-speed train running at 400km/h:
              these are just some of our achievements that carry the kudos of
              being the first, largest or best. Instead of resting on our
              laurels, we will continue to move forward to create a better
              future.
            </div>
          </div>

          <div className="item_detail item_border">
            <img
              src="/Images/Group31802.svg"
              className="image"
              alt="Philosophy. Respectation for Mankind"
              title="Philosophy. Respectation for Mankind"
            ></img>
            <h1 className="id_title">Respectation for Mankind</h1>
            <div className="id_des">
              What is the ultimate reason for a company’s existence?
              Manufacturing safe, convenient vehicles and fast trains,
              developing cutting-edge technologies, and constructing high-tech
              buildings are all meaningless if it is not aimed at improving
              people’s lives. Hyundai Motor Group aspires to help raise the
              quality of life by offering better products and services to more
              people, at a faster rate. At the same time, Hyundai Motor Group
              will stay committed to delivering greater value as well as
              realizing co-prosperity among the company, people and society to
              become a respected global corporate citizen.
            </div>
          </div>
        </div>
      </div>
    );
  }
};
