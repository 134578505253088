import React, {useState} from 'react';
import { Modal } from 'antd';
import ButtonComponent from '../Buttons';
import { toast } from 'react-toastify';
import {MESSAGE} from '../../constants/error';
import './style.scss';

const MyModal = (props) => {
  let {
    title = null,
    cancelText = '취소',
    okText = '확인',
    autoDismiss = false,
  } = props;
  let [visible, setVisible] = useState(props.visible);
  if (autoDismiss) {
    setTimeout(() => {
      setVisible(false);
    }, 2000);

  }

  if (props.type === ModalType.success) {

  } else if (props.type === ModalType.confirm) {
    return (
      <Modal
        {...props}
        visible={visible}
        width="576px"
        title={title}
        closable={true}
        cancelText={cancelText}
        okText={okText}
        footer={
            <ButtonComponent
              Background="#fff"
              Border="1px solid #002A5B"
              text="취소"
              Color="002C5F"
              Padding="0 30px"
            />
        }
      >
        <div>
          <img src="/Images/Group 31719.svg" alt=''/>
          <div>
            <span>무언가 잘못되었다.</span>
            <span>이 메뉴를 만들 수 없습니다. 다시 확인하십시오!</span>
          </div>
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal
        {...props}
        visible={visible}
        width="576px"
        title={title}
        closable={props.type === 'success' ? null : true}
        cancelButtonProps={props.type === 'fail' ? null : true}
        cancelText={cancelText}
        okText={okText}
        footer={
            <ButtonComponent
              Background="#fff"
              Border="1px solid #002A5B"
              text="취소"
              Color="002C5F"
              Padding="0 30px"
            />
        }
      ></Modal>
    );
  }
};

export const confirm = (option = {}, content) => {
  Modal.confirm({
    title: '변경 사항을 저장?',
    content: (
      <div>
        <div>{content || MESSAGE.C_MSG_9}</div>
      </div>
    ),
    closable: true,
    maskClosable: true,
    icon: null,
    cancelText: 'No',
    okText: 'Yes',
    autoFocusButton: null,
    ...option
  });
};

export const confirmDelete = (message, option = {}) => {
  Modal.confirm({
    content: (
      <div>
        <div>{message}</div>
      </div>
    ),
    closable: true,
    maskClosable: true,
    icon: null,
    cancelText: 'No',
    okText: 'Yes',
    ...option
  });
};

export const showError = (option = {}) => {
  Modal.error({
    title: null,
    content: (
      <div>
        <img src="/Images/Group 31719.svg" alt=''/>
        <div>
          <span>무언가 잘못되었다.</span>
          <span>이 메뉴를 만들 수 없습니다. 다시 확인하십시오!</span>
        </div>
      </div>
    ),
    closable: true,
    maskClosable: true,
    icon: null,
    cancelText: '취소',
    okText: '확인',
    onOk: () => console.log(),
    ...option
  });
};

export const showToast = (message = 'Success Notification !', option = {}) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true,
    className: 'showToast',
    ...option
  });
};

export const showToastError = (message = 'Error Notification !', option = {}) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true,
    className: 'showToastError',
    autoClose: false,
    ...option
  });
};

export default MyModal;

export const ModalType = {
  success: 'success',
  false: 'false',
  confirm: 'confirm'
};
