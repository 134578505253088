import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LayoutReport from '../../hocs/LayoutReport';
import Guideline from './Guideline';
import RealName from './RealName';
import Certificate from './CopyCertificationNumber';
import ReportDetail from './ReportDetail';
import ReportReply from './Reply';
import ConfirmProcess from './ConfirmationOfProcessingResult';
import NotFound from '../../pages/NotFound';

export default ({ match }) => {
  const path = match.path;
  return (
    <LayoutReport>
      <Switch>
        <Route path={`${path}/certificate`} component={Certificate} />
        <Route path={`${path}/guideline`} component={Guideline} />
        <Route path={`${path}/create`} component={RealName} />
        <Route path={`${path}/report-detail`} component={ReportDetail} />
        <Route path={`${path}/report-reply`} component={ReportReply} />
        <Route path={`${path}/confirm-process`} component={ConfirmProcess} />
        {/* <Route component={Guideline} /> */}
        <Route component={NoMatchPage} />
      </Switch>
    </LayoutReport>
  );
};
const NoMatchPage = () => {
  return (
    <div id="main">
        <NotFound />
    </div>
  );
};
