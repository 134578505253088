import React from 'react';

export default (code) => {
  if (code.code === 'kr') {
    return (
      <div>
        <h1 className='hTagHidden'>비전</h1>
        <div className="plcp_detail3">
          <h1 className="plcp_title" lang='en'>Together for a better future</h1>
          <div className="plcp_des_highlight">더 나은 미래를 향한 동행</div>
          <div className="plcp_des">
            현대자동차그룹의 비전 ‘더 나은 미래를 향한 동행’은 기업의 외형적
            성장을 넘어 최상의 만족을 고객에게 전달하고자 하는 굳은 의지를
            담았습니다. 자동차, 철강, 건설을 중심으로 다양한 부문에서 시너지를
            창출하는 현대자동차그룹만의 순환형 기업구조는 우리 생활 곳곳에
            혁신을 일으키며 새로운 가치를 창조하고 있습니다. 인간존중과
            환경친화적 경영을 기본 원칙으로 삼아 글로벌 선도 그룹의 위상에 맞게
            발전해나갈 것 입니다.
          </div>
        </div>
        <div className="plcp_content3">
          <div className="cpc_image">
            <img src="/Images/bgr_vision_kr.svg" alt="현대자동차그룹의 순환형 기업구조. 철강-부품-자동차-건설-IT-금융-철차/중기-물류의 사업분야로 구성" title="현대자동차그룹의 순환형 기업구조" ></img>
          </div>
          <div className="cpc_lst_image">
            <div className="cpcl_content">
              <div
                className="detail_content detail1"
                alt="자동차에서 삶의 동반자로"
                title="자동차에서 삶의 동반자로"
              >
                <div className="content_bgr">
                  <h1 className="title_bgr">자동차에서 삶의 동반자로</h1>
                  <div className="note_bgr">
                    Lifetime partner in automobiles and beyond
                  </div>
                </div>
              </div>
              <div className="text_content">
                인간중심적이고 환경친화적인 혁신 기술과 포괄적 서비스를 기반으로
                최상의 이동성을 구현하여 삶을 더욱 편리하고 즐겁게 영위할 수
                있는 새로운 공간을 제공합니다.
              </div>
            </div>

            <div className="cpcl_content">
              <div
                className="detail_content detail2"
                alt="새로운 철강 시대의 리더"
                title="새로운 철강 시대의 리더"

              >
                <div className="content_bgr">
                  <h1 className="title_bgr">새로운 철강 시대의 리더</h1>
                  <div className="note_bgr">Leading the new era of steel</div>
                </div>
              </div>
              <div className="text_content">
                친환경, 자원순환형 기업으로서 대내외 유기적 협력관계를 기반으로
                고도화된 제품 및 서비스를 제공하고 최상의 글로벌 경쟁력을
                구현하여 새로운 철강시대를 선도합니다.
              </div>
            </div>
            <div className="cpcl_content">
              <div
                className="detail_content detail3"
                alt="함께 내일을 창조하는 기업"
                title="함께 내일을 창조하는 기업"
              >
                <div className="content_bgr">
                  <h1 className="title_bgr">함께 내일을 창조하는 기업</h1>
                  <div className="note_bgr">We Build Tomorrow</div>
                </div>
              </div>
              <div className="text_content">
                글로벌 종합엔지니어링 기업으로서 유관산업과의 유기적 협력을
                토대로 미래 기술과 산업의 융·복합화를 주도하여 더 좋은 삶의
                기반을 창조합니다.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h1 className='hTagHidden'>Vision</h1>
        <div className="plcp_detail3">
          <h1 className="plcp_title">Together for a better future</h1>
          <div className="plcp_des_highlight">
            &apos;Together for a better future&apos; is our new vision
          </div>
          <div className="plcp_des">
            &apos;Together for a better future&apos; is our new vision, representing
            Hyundai Motor Group’s firm commitment to the highest customer
            satisfaction, as well as qualitative growth. Our resource
            circulating business structure generates synergy among business
            sectors - especially automotive, steel and construction - and
            creates new value by bringing innovation to customers’ lives in
            diverse ways. With respect for people and the environment, Hyundai
            Motor Group will pursue further growth in a way befitting a world
            leading enterprise.
          </div>
        </div>
        <div className="plcp_content3">
          <div className="cpc_image">
            <img src="/Images/bgr_vision_en.svg" alt="Hyundai Motor Group's resource circulating business structure. Consisting of the business areas of Steel-Parts-Automobile-Construction-IT-Finance-Rolling stock/Heavy Machinery-Logistics." title="Hyundai Motor Group's resource circulating business structure" ></img>
          </div>
          <div className="cpc_lst_image">
            <div className="cpcl_content">
              <div
                className="detail_content detail1"
                alt="Lifetime partner in automobiles and beyond"
                title="Lifetime partner in automobiles and beyond"
              >
                <div className="content_bgr">
                  <h1 className="title_bgr">
                    Lifetime partner in automobiles and beyond
                  </h1>
                  {/* <div className="note_bgr">
                    Lifetime partner in automobiles and beyond
                  </div> */}
                </div>
              </div>
              <div className="text_content">
                To become a trusted lifetime partner of our customers, we will
                bring a new perspective to automobiles through innovative
                mobility solutions based on human-centric, eco-friendly
                technologies and services.
              </div>
            </div>

            <div className="cpcl_content">
              <div
                className="detail_content detail2"
                alt="Leading the new era of steel"
                title="Leading the new era of steel"
              >
                <div className="content_bgr">
                  <h1 className="title_bgr">Leading the new era of steel</h1>
                  {/* <div className="note_bgr">Leading the new era of steel</div> */}
                </div>
              </div>
              <div className="text_content">
                As an eco-friendly, resource-circulation company, we will lead a
                new era in the steel industry by providing high value-added
                products and services and realizing world’s best competitiveness
                based on cooperative relations with our stakeholders.
              </div>
            </div>
            <div className="cpcl_content">
              <div
                className="detail_content detail3"
                alt="We Build Tomorrow"
                title="We Build Tomorrow"
              >
                <div className="content_bgr">
                  <h1 className="title_bgr">We Build Tomorrow</h1>
                  {/* <div className="note_bgr">We Build Tomorrow</div> */}
                </div>
              </div>
              <div className="text_content">
                As a global leading provider of high-value engineering
                solutions, we will create the foundation for a better life
                through cross-business synergy and convergence with future
                technologies.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
