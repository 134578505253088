import React, { Component } from 'react';
import { Tooltip } from 'antd';

import './style.scss';
class FaqPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.setState({ hidden: true });
    }
  }

  onShowContent = content => (
    <div className="faq-post-content" dangerouslySetInnerHTML={{ __html: content }} />
  );

  handleClick = () => {
    this.setState((state) => ({
      hidden: !state.hidden
    }));
  }

  render() {
    const { faq, index } = this.props;
    return (
      <div className="faq-post">
        <div className="faq-post-title">
          <div className="title-left">
            <div className="post-stt">{index + 1}</div>
            <Tooltip placement="bottomLeft" title={faq.title}>
              <p className="post-title-text">{faq.title}</p>
            </Tooltip>
          </div>
          <div className="title-right" onClick={() => this.handleClick()}>
            {this.state.hidden ? <img src="/Images/Component991.png" alt="+" /> : <img src="/Images/icclose.svg" alt="x" />}
          </div>
        </div>
        {this.state.hidden ? '' : this.onShowContent(faq.content)}
      </div>
    );
  }
}

export default FaqPost;
