import * as Types from '../types/faq';

export const listContentFaq = (typeCode, page, pageSize) => {
	return {
		type: Types.GET_CONTENT_FAQ,
		payload: {
			typeCode,
			page,
			pageSize
		}
	};
};
