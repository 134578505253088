import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import './style.scss';
import { withLocalize } from 'react-localize-redux';

const SanHead = styled.span`
  font-family: HDharmonyL;
  letter-spacing: -0.64px;
  font-size: 16px;
  line-height: 30px;
`;

class SideBarItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBar: 'sideBarActive'
    };
    // this.setWrapperRef = this.setWrapperRef.bind(this);
    // this.handleClickSideBar = this.handleClickSideBar.bind(this);
  }

  // componentDidMount() {
  //   document.addEventListener('mousedown', this.handleClickSideBar);
  // }

  // componentWillUnmount() {
  //   document.removeEventListener('mousedown', this.handleClickSideBar);
  // }

  // /**
  //  * Set the wrapper ref
  //  */
  // setWrapperRef(node) {
  //   this.wrapperRef = node;
  // }

  /**
   * change state if clicked on outside of element
   */
  // handleClickSideBar(event) {
  //   if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
  //     this.setState({
  //       sideBar: 'sideBarActive'
  //     });
  //   } else {
  //     this.setState({
  //       sideBar: 'sideBarHidden'
  //     });
  //   }
  // }

  render() {
    // const data = this.state;
    const { data, text, translate } = this.props;
    return (
      <div
        className={`side_bar_item_wrap ${this.state.sideBar}`}
      // ref={this.setWrapperRef}
      >
        <div className="side_bar_item_wrap_wrap wrap_1" >
          <span to="/" className="side_bar_item" onClick={() => {
            if (this.state.sideBar === 'sideBarActive') {
              this.setState({
                sideBar: 'sideBarHidden',
              });
            }
            else {
              this.setState({
                sideBar: 'sideBarActive',
              });
            }
          }}>
            {text}
          </span>
          <div className="side_bar_detail">
            <div>

              {data.map((item, index) => {
                return (
                  <a href={item.url} key={index}>
                    <SanHead className="content">{item.name}</SanHead>
                  </a>
                );
              })}
            </div>
            {/* 
            <Link to="/" hidden ={true ? !line2 : false}>
              <SanHead className="content" >{line2}</SanHead>
            </Link>
            <Link to="/" hidden={true ? !line3 : false}>
              <SanHead className="content" >{line3}</SanHead>
            </Link>
            <Link to="/" hidden={true ? !line4 : false}>
              <SanHead className="content" >{line4}</SanHead>
            </Link> */}
          </div>
        </div>
        <img
          className="side_bar_arrow"
          src="/Images/ickeyboardarrowdown24px.svg"
          alt={this.state.sideBar  !== 'sideBarActive' ? translate('webAccessibility.openMenuButton') : translate('webAccessibility.closeMenuButton')}
          onClick={() => {
            if (this.state.sideBar === 'sideBarActive') {
              this.setState({
                sideBar: 'sideBarHidden',
              });
            }
            else {
              this.setState({
                sideBar: 'sideBarActive',
              });
            }
          }}
        />
      </div>
    );
  }
}

export default withLocalize(SideBarItem);
