import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import CoreValue from '../../../components/DefaultContent/Philosophy/coreValue';
import './style.scss';

class CoreValues extends Component {
  componentDidMount() {
    var activeTabDom = document.getElementsByClassName('ethical_tab_childrent');
    if (this.props.tabClick != '2') {
      activeTabDom[1].focus();
      activeTabDom[1].click();
      setTimeout(() => {
        document.getElementsByClassName('et_link')[1].focus();
      }, 200); 
    }
    document.getElementsByClassName('et_link')[0].removeAttribute('title');
    document.getElementsByClassName('et_link')[1].setAttribute('title', this.props.activeLanguage.code === 'en' ? 'Selected' : '선택됨');
    document.getElementsByClassName('et_link')[2].removeAttribute('title');
    activeTabDom[1].className = 'ethical_tab_childrent ethical_active';
    activeTabDom[0].className = 'ethical_tab_childrent ethical_inactive';
    activeTabDom[2].className = 'ethical_tab_childrent ethical_inactive';
    this.props.func('0');
  }
  render() {
    const { data, activeLanguage } = this.props;
    const { code } = activeLanguage;
    
    if (data.length > 0) {
      return data.slice(0, 1).map((item, index) => {
        return (
          <div key={index} dangerouslySetInnerHTML={{ __html: item.content }}>
            {/* <div className="plcp_detail2">
              <div className="plcp_title">{item ? item.title : null}</div>
              <div className="plcp_des">{item ? item.describe : null}</div>
            </div>

            <div className="plcp_content2 real_content_detail">
              <div
                className={`content_detail ${code === 'en' ? 'content_en' : null}`}
                dangerouslySetInnerHTML={{ __html: item.content }}
              ></div>
            </div> */}
          </div>
        );
      });
    } else {
      return <CoreValue code={code} />;
    }
  }
}

export default withLocalize(CoreValues);
