import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import SitemapLayout from '../../hocs/SitemapLayout';
import { withLocalize } from 'react-localize-redux';
import { selectAllMenu } from '../../services/api';
import { HYUNDAI_SITE_ID } from '../../constants';
import './style.scss';

const SanHead = styled.span`
  font-family: HyundaiSansHeadKR;
`;

class SiteMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataMenu: [],
      pathName: null,
      id: null,
      loading: true,
      listMenu: null,
      setClass: null
    };
  }

  componentDidMount() {
    this.loadMenu(this.props);
    // var isOnIOS = navigator.userAgent.match(/iPad/i)|| navigator.userAgent.match(/iPhone/i);
    // var eventName = isOnIOS ? 'pagehide' : 'beforeunload';
    // window.addEventListener(eventName, function (e) {
    //   // Cancel the event
    //   e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    //   // Chrome requires returnValue to be set
    //   window.event.cancelBubble = true;
    //   e.returnValue = '';
    //   window.event.cancelBubble = true;
    // });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.activeLanguage.code !== nextProps.activeLanguage.code) {
      this.loadMenu(nextProps);
    }
  }
  loadMenu = async props => {
    let lang = props.activeLanguage.code;
    let urlSites = HYUNDAI_SITE_ID;
    let result = await selectAllMenu(urlSites, lang);
    this.setState({
      dataMenu: result.data.data.menuInfoTree,
      setClass: lang === 'en' ? 'langActive' : 'null'
    });
  };

  render() {
    const data = this.state.dataMenu;
    const {translate} = this.props;
    return (
      <SitemapLayout>
        
        <Helmet>
          <title>{translate('helmet.sitemap')}</title>
        </Helmet>
        <div className="site_map_wrap">
          <div className="sitemapWrap sitemap-wrap">
            <div className="sitemapList">
              <div className="sitemapList_wrap">
                <div className="row">
                  {data
                    ? data.slice(0, 3).map((item, index) => {
                        return (
                          <div className={'wrap-content'} key = {index}>
                            <SanHead className="title">
                              {item.name.replace()
                                ? item.name.replace(
                                    /[&\/\\#,+()$~%.'":*?<>{}]/g,
                                    ''
                                  )
                                : null}
                            </SanHead>
                            <div className="content">
                              {item.children
                                ? item.children.map((item, index) => {
                                    return (
                                      <Link to={item.url} key={index}>
                                        <SanHead className="content1">
                                          {item.name.replace()
                                            ? item.name.replace(
                                                /[&\/\\#,+()$~%.'":*?<>{}]/g,
                                                ''
                                              )
                                            : null}
                                        </SanHead>
                                      </Link>
                                    );
                                  })
                                : null}
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </SitemapLayout>
    );
  }
}

export default withLocalize(SiteMap);
